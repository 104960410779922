import { SelectSearch } from '@onbeef/components/input';
import { useMerchantStore } from '../../stores/merchant';
import { useOrderDetails } from '../../stores/order-details';
import CardMethodPayment from '../Cards/CardMethodPayment';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useCartStore } from '../../stores/cart';
import cx from 'classnames';

type OptionSearch = {
  value: string;
  label: React.ReactNode | string;
};

const ListDeliveryMethods: React.FC = () => {
  const isExtension = useCartStore((state) => state.isExtension);

  const { payment_methods } = useMerchantStore((state) => ({
    payment_methods: state.payment_methods,
  }));
  const { setSelectedPaymentMethod, selectedPaymentMethod } = useOrderDetails(
    (state) => ({
      setSelectedPaymentMethod: state.setSelectedPaymentMethod,
      selectedPaymentMethod: state.selectedPaymentMethod,
    }),
  );

  const { paymentMethodsList, cardMethodPayment } = React.useMemo(() => {
    console.log('methods', isExtension, payment_methods);
    const payments = isExtension
      ? payment_methods
      : payment_methods?.filter((item) => !item.name.includes('Whatsapp'));

    const paymentMethodsList = payments
      ?.filter((payment) => payment.pivot?.status === 1)
      .map((payment) => {
        return {
          label: `${payment.name}`,
          value: payment.name,
        };
      });

    console.log('payments', paymentMethodsList);

    const cardMethodPayment = payments?.map((payment) => {
      if (payment.pivot?.status !== 1) {
        return null;
      }

      return (
        <CardMethodPayment
          slug={payment.name}
          name={payment.name}
          image={payment.image}
          selectedValue={selectedPaymentMethod}
          onClick={() => {
            setSelectedPaymentMethod(payment.name);
            const element = document.getElementById('checkoutScreen');
            element?.scrollIntoView({ behavior: 'smooth' });
          }}
        />
      );
    });

    return {
      paymentMethodsList,
      cardMethodPayment,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_methods, selectedPaymentMethod]);

  const handleSelectPaymentMethod = (value: string) => {
    setSelectedPaymentMethod(value);
  };

  const { setValue } = useFormContext();

  React.useEffect(() => {
    setValue('payment_method', selectedPaymentMethod);
  }, []);

  if (!payment_methods || payment_methods?.length < 1) return null;

  return (
    <React.Fragment>
      <div className={`${!isExtension ? 'grid grid-cols-2 gap-2' : 'flex'} `}>
        {isExtension && paymentMethodsList && (
          <>
            <SelectSearch
              placeholder="Selecione uma forma de pagamento"
              name="payment_method"
              className="w-full"
              onSelectOption={handleSelectPaymentMethod}
              options={paymentMethodsList as OptionSearch[]}
            />
          </>
        )}

        {!isExtension && cardMethodPayment}
      </div>
      {selectedPaymentMethod &&
        payment_methods.find((p) => p.name === selectedPaymentMethod)?.pivot
          ?.comments && (
          <div
            className={cx(
              'mt-2 w-full flex rounded-md bg-primary gap-1 p-2 text-contrastText',
              isExtension ? 'mb-4' : 'mb-32',
            )}
          >
            <b>Observação:</b>
            {
              payment_methods.find((p) => p.name === selectedPaymentMethod)
                ?.pivot?.comments
            }
          </div>
        )}
    </React.Fragment>
  );
};

export default ListDeliveryMethods;
