//import { useEffect, useContext } from 'react';
import imageError from '../../assets/images/error.png';
import * as Sentry from '@sentry/react';
import { getBrowserInfo, requestTrackingServer } from '../../utils/function';
import { JsErrorObject } from '../../queries/home/types';

const eventId = Sentry.captureMessage(`Page JS Error Has Occurred`);

function ErrorPage({ error }: { error: Error }) {
  if (error) {
    // eslint-disable-next-line no-useless-escape
    const fileName = error?.stack
      ? error?.stack?.match(/\/([^\/]+):/)?.['1']
      : 'N/A';
    const obj: JsErrorObject = {
      name: error?.name,
      message: error?.message,
      cause: error?.stack as string,
      stack: error?.stack as string,
      currentUrl: window.location.href,
      fileName: fileName,
    };
    console.error('Aconteceu um erro inesperado!', obj);

    const browserInfo = getBrowserInfo();
    requestTrackingServer('/v1/error/collect', {
      method: 'POST',
      body: JSON.stringify([
        {
          key: 'event_id',
          value: eventId,
        },
        {
          key: 'error_data',
          value: JSON.stringify(obj),
        },
      ]),
      headers: {
        browserInfo: JSON.stringify(browserInfo),
      },
    });
  }

  return (
    <div className="transition-opacity duration-300">
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col w-full text-center p-4">
          <h1 className="text-xl font-bold text-black">
            Aconteceu um erro inesperado!
          </h1>
          <img
            src={imageError}
            alt="504"
            className="self-center w-[60%] md:w-64"
            loading="lazy"
          />
          <h1 className="text-lg font-bold text-black">
            <small>Código do erro: #{eventId}</small> <br />
          </h1>
          <div className="flex justify-center items-center">
            <button
              className="w-fit rounded-lg bg-rose-700 p-2 text-contrastText mt-4"
              onClick={() => {
                window.history.back();
              }}
            >
              Voltar para última página
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ErrorPage;
