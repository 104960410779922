import { useState, useEffect } from 'react';
import './Map.css';
import { Skeleton } from '@onbeef/components/skeleton';

const MapContainer = (props: any) => {
  const [map, setMap] = useState(null);

  const [mapLoaded, setMapLoaded] = useState(false);

  const [isGettingAddress, setIsGettingAddress] = useState(true);

  const [consideredAddress, setConsideredAddress] = useState<{
    formatted_address: string;
  }>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // @ts-ignore
    if (window?.google && window?.google.maps) {
      setMapLoaded(true);
      //console.log('mapLoaded', mapLoaded);
    }
  });

  // Initialize map
  useEffect(() => {
    if (mapLoaded) {
      // @ts-ignore
      const map2 = new window.google.maps.Map(
        document.getElementById('map-address'),
        {
          center: {
            lat: props?.addressData?.latitude
              ? Number(props.addressData.latitude)
              : -16.6632299,
            lng: props?.addressData?.longitude
              ? Number(props.addressData.longitude)
              : -49.2744486,
          }, // Initial center doesn't matter
          zoom: 18, // Zoom level,
          disableDefaultUI: true,
          clickableIcons: false,
          gestureHandling: 'greedy',
        },
      );

      setMap(map2);

      // Event listener for map idle (stopped moving)
      map2.addListener('idle', () => {
        const center = map2.getCenter();
        getAddress(center);
      });

      map2.addListener('dragstart', () => {
        // add beingDragged class to #map
        document?.getElementById('map-address')?.classList.add('beingDragged');
        setIsGettingAddress(true);
      });

      map2.addListener('dragend', () => {
        const center = map2.getCenter();
        getAddress(center);
        document
          ?.getElementById('map-address')
          ?.classList.remove('beingDragged');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaded]);

  useEffect(() => {
    if (props.addressData) {
      setConsideredAddress(props.addressData);

      // set map lat lng
      if (map) {
        // @ts-ignore
        map.setCenter({
          lat: Number(props.addressData.latitude),
          lng: Number(props.addressData.longitude),
        });
      }
    }
  }, [props.addressData]);

  useEffect(() => {
    const setElementHeight = () => {
      const viewportHeight = window.innerHeight;
      const element = document.getElementById('map-address');
      if (element) {
        const adjustedHeight = viewportHeight * 0.8;
        element.style.height = adjustedHeight + 'px';
      }
    };
    setElementHeight();

    window.addEventListener('resize', setElementHeight);
    return () => {
      window.removeEventListener('resize', setElementHeight);
    };
  }, []);

  // Function to get address from coordinates
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAddress = (location: any) => {
    setIsGettingAddress(true);
    // @ts-ignore
    const geocoder = new window.google.maps.Geocoder();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geocoder.geocode({ location: location }, (results: any, status: any) => {
      setIsGettingAddress(false);
      if (status === 'OK') {
        const [, ...rest] = props.addressData.street_name.split(' ');
        const streetName = rest.join(' ').toLowerCase();
        let i = results.findIndex((r: any) => {
          return r.formatted_address?.toLowerCase().includes(streetName);
        });
        if (i === -1) i = 0;
        if (results[i]) {
          setConsideredAddress(results[i]);
        }
      }
    });
  };

  return (
    <div className="relative">
      <div className="address-maps-header flex justify-center text-center">
        <div>
          <h2>Você está aqui?</h2>
          <Skeleton
            width={'100%'}
            height={'20px'}
            borderRadius="10px"
            isLoading={isGettingAddress}
          >
            <p>
              {consideredAddress ? consideredAddress.formatted_address : ''}
            </p>
          </Skeleton>
          <small>
            <p>Arraste o mapa para ajustar a localização</p>
          </small>
        </div>
      </div>
      <div id="map-address" className="relative w-full"></div>
      <div
        className="w-full absolute flex justify-center"
        style={{ marginTop: '-50px' }}
      >
        {!isGettingAddress && (
          <button
            type="button"
            className="min-w-5 p-2 text-contrastText bg-primary rounded-md text-sm flex justify-center"
            onClick={() => {
              props.onConfirmAddress(consideredAddress);
            }}
          >
            Confirmar localização
          </button>
        )}
      </div>
    </div>
  );
};

export default MapContainer;
