import { LoadingIcon, LocationIcon } from '@onbeefapp/constants';
import React from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

interface AddressSearchProps {
  defaultStreetName: string;
  handleSelectedAddress: (place: any) => void;
  handleAddressNotFound: () => void;
}

export const AddressSearch: React.FC<AddressSearchProps> = ({
  defaultStreetName,
  handleSelectedAddress,
  handleAddressNotFound,
}) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    language: 'pt-BR',
    apiKey: 'AIzaSyBt55rofDfwKXQhaz9zFRetA-Rrhugr4Fg',
    options: {
      types: ['address'],
      componentRestrictions: { country: 'br' },
    },
  });

  const [input, setInput] = React.useState('');

  const onSelectAddress = (item: (typeof placePredictions)[number]) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails: any) => {
        getPlacePredictions({ input: '' });
        handleSelectedAddress(placeDetails);
      },
    );
  };

  return (
    <>
      <input
        id="google-autocomplete"
        defaultValue={defaultStreetName}
        placeholder="Buscar endereço e número"
        className="w-full text-sm bg-white px-2 py-4 outline-current rounded-md border"
        onChange={(evt) => {
          setInput(evt.target.value);
          getPlacePredictions({ input: evt.target.value });
        }}
      />
      <div className="flex flex-col">
        {isPlacePredictionsLoading ? (
          <div className="w-full flex items-center justify-center py-4">
            <LoadingIcon className="text-black" />
          </div>
        ) : (
          placePredictions.map((item) => (
            <button
              type="button"
              className="flex gap-4 px-4 py-2 items-center"
              onClick={() => onSelectAddress(item)}
            >
              <LocationIcon />
              <div className="flex flex-col gap-1 text-left">
                <span className="font-medium">
                  {item.structured_formatting.main_text}
                </span>
                <span className="text-sm">
                  {item.structured_formatting.secondary_text}
                </span>
              </div>
            </button>
          ))
        )}
        {input && (
          <button
            type="button"
            className="flex gap-4 px-4 py-2 items-center"
            onClick={handleAddressNotFound}
          >
            <LocationIcon className="text-red-600" />
            <div className="flex flex-col gap-1 text-left">
              <span className="font-medium text-red-700">
                Não achei meu endereço
              </span>
              <span className="text-sm">Quero tentar de outra forma</span>
            </div>
          </button>
        )}
      </div>
    </>
  );
};
