import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import api from '../../services/api';
import {
  UpdateSubscriptionResponse,
  ChangeSubscriptionPaymentMethodPayload,
  ClubSubscriptionPayload,
  ClubSubscriptionResponse,
  SubscriptionStatusResponse,
} from './types';

export const createClubSubscription = async (
  payload: ClubSubscriptionPayload,
) => {
  const { data } = await api.post<ClubSubscriptionResponse>(
    '/all/customers/clubs/subscription',
    payload,
  );
  return data;
};

export const cancelClubSubscription = async (subs_id: string) => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await api.delete<UpdateSubscriptionResponse>(
    `/customers/clubs/subscription/${subs_id}`,
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    },
  );
  return data;
};

export const changeClubPaymentMethod = async (
  payload: ChangeSubscriptionPaymentMethodPayload,
) => {
  const { data } = await api.put<UpdateSubscriptionResponse>(
    `/customers/clubs/subscription/${payload.subs_id}/payments`,
    { card: payload.card },
  );
  return data;
};

export const getSubscriptionStatus = async (ctx: QueryFunctionContext) => {
  const [, subs_id] = ctx.queryKey;
  const { data } = await api.get<SubscriptionStatusResponse>(
    `/customers/clubs/subscription/${subs_id}/status`,
  );
  return data;
};

export const phoneHasClub = async ({
  phone,
  id,
}: {
  phone: string;
  id: string;
}) => {
  const { data } = await api.get<{ has: boolean }>(
    `/customers/${phone}/merchants/${id}/has-club`,
  );
  return data;
};

export function useCreateClubSubscription() {
  return useMutation(['createClubSubscription'], createClubSubscription);
}

export function useCancelClubSubscription() {
  return useMutation(['cancelClubSubscription'], cancelClubSubscription);
}

export function useChangeClubPaymentMethod() {
  return useMutation(['changeClubPaymentMethod'], changeClubPaymentMethod);
}

export function useGetSubscriptionStatus(subs_id: string) {
  return useQuery(['getSubscriptionStatus', subs_id], getSubscriptionStatus);
}

export function usePhoneHasClub() {
  return useMutation(['phoneHasClub'], phoneHasClub);
}
