import {
  formatGrams,
  KitItems,
  KitProduct,
  ProductType,
} from '@onbeefapp/constants';
import { KitItemsPayload } from '../queries/order/types';
import { StoreKit, StoreKitItem } from '../stores/kits';

export const formatKitsPayload = (kitItems: StoreKit): KitItemsPayload => {
  const payload: KitItemsPayload['categories'] = [];
  Object.keys(kitItems).forEach((catID) => {
    payload.push({
      id: catID,
      products: (kitItems[catID] || []).map((item) => ({
        cut_id: item.cut_id,
        id: item.id,
        quantity: item.quantity,
      })),
    });
  });
  return { categories: payload };
};

export const calculateSelectedKitItemsTotalValue = (items?: StoreKit) => {
  if (!items) return 0;
  let total = 0;
  Object.values(items).forEach((cat) => {
    Object.values(cat).forEach((item) => {
      total += item.quantity * item.price;
    });
  });
  return total;
};

export const calculateKitItemsTotalGrams = (items?: KitItems) => {
  if (!items) return 0;
  return items.categories?.reduce(
    (acc, cur) =>
      acc +
      cur?.products?.reduce(
        (acc, cur) =>
          acc +
          (cur.product.type === ProductType.KG ||
          cur.product.price_per_kilo != null
            ? cur.quantity
            : 0),
        0,
      ),
    0,
  );
};

export const calculateKitProductPrice = (item: KitProduct) => {
  return item.product.price_per_kilo
    ? (item.product.price_per_kilo / 1000) * item.quantity
    : item.product.price_per_unit! * item.quantity;
};

export const getKitProductLabel = (item: KitProduct) => {
  return `${item.product.name} ${item.cut ? item.cut.label : ''}`;
};

export const formatKitProductQuantity = (
  quantity: number,
  item: KitProduct,
) => {
  const isKg =
    item.product.type === ProductType.KG || item.product.price_per_kilo != null;
  const calc = quantity * item.quantity;
  return `${isKg ? formatGrams(String(calc)) : calc} ${!isKg ? 'und' : ''}`;
};

export const isSameKitItem = (storeItem: StoreKitItem, item: KitProduct) => {
  return storeItem.item_id === item.kit_product_id;
};
