import React from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from '../../components/Skeleton/Skeleton';
import { useGetSchedulings } from '../../queries/order';
import { useCartStore } from '../../stores/cart';
import { useMerchantStore } from '../../stores/merchant';
import CardScheduling from '../Cards/CardScheduling';

interface Props {
  handleExtensionMoreSchedulings?: () => void;
}

const ListSchedulings: React.FC<Props> = ({
  handleExtensionMoreSchedulings,
}) => {
  const isExtension = useCartStore((state) => state.isExtension);

  const navigate = useNavigate();

  const { merchant, delivery, nextHour, isClosedReason } = useMerchantStore(
    (state) => ({
      merchant: state.merchant,
      delivery: state.delivery,
      nextHour: state.nextHour,
      isClosedReason: state.isClosedReason,
    }),
  );

  const { data, isLoading } = useGetSchedulings(merchant?.id);
  const discount = data?.discounts?.[0];
  const deliveryTime = useCartStore((state) => state.deliveryTime);

  const seeMoreScheduling = () => {
    if (isExtension) {
      handleExtensionMoreSchedulings && handleExtensionMoreSchedulings();
    } else {
      navigate(`/schedule`);
    }
  };

  return (
    <div className="flex flex-col mt-4 mb-2 space-y-1">
      <Skeleton
        isLoading={isLoading && data != null}
        width="100%"
        height="100px"
      >
        {data?.daysAvailable && data.status === 'ACTIVE' ? (
          <div className="flex flex-col scrollable w-full p-1">
            <div className="flex justify-between w-full">
              <p className="font-medium text-sm">Tempo estimado de entrega</p>
              {merchant?.delivery_details !== 6 && (
                <button
                  className="text-primary text-sm font-semibold"
                  onClick={seeMoreScheduling}
                >
                  Ver horários
                </button>
              )}
            </div>
            <div className="flex flex-row overflow-x-auto w-max-full mt-2 space-x-2 w-full">
              {deliveryTime &&
              data.daysAvailable[0] &&
              deliveryTime.date !== data.daysAvailable[0].date ? (
                <CardScheduling
                  baseDiscount={discount}
                  date={deliveryTime.date}
                  selected={true}
                  time={deliveryTime.time}
                />
              ) : (
                data.daysAvailable[0]?.times.map((time, index) => {
                  const day = data.daysAvailable[0];
                  const selected = deliveryTime
                    ? deliveryTime.date === day?.date &&
                      deliveryTime.time_interval.start === time.start
                    : time.default;
                  if (day && day.date) {
                    return (
                      <CardScheduling
                        key={index}
                        baseDiscount={discount}
                        date={day.date}
                        selected={selected}
                        time={time}
                      />
                    );
                  }
                })
              )}
            </div>
          </div>
        ) : (
          <div className="flex p-2">
            <span className="font-medium text-xs">
              {delivery &&
                (delivery?.time.includes('Cerca de ') ? '' : 'Cerca de ') +
                  delivery?.time +
                  (isClosedReason != null && nextHour
                    ? ' - Entraremos em contato ' +
                      nextHour.day +
                      ' as ' +
                      nextHour.start +
                      ' para confirmar o pedido.'
                    : '')}
            </span>
          </div>
        )}
      </Skeleton>
    </div>
  );
};

export default ListSchedulings;
