import CardProduct from '../../components/Cards/CardProduct';
import SkeletonProduct from '../../components/Skeleton/SkeletonProduct';
import { Product } from '@onbeefapp/constants';
import * as React from 'react';

interface ListProductProps {
  products: Product[];
}

const ListProduct: React.FC<ListProductProps> = ({ products }) => {
  const [cols, setCols] = React.useState(3);

  const updateCols = () => {
    const windowWidth = window.innerWidth - 600;
    const calculatedCols = Math.floor(windowWidth / 104);
    setCols(calculatedCols > 3 ? calculatedCols : 3);
  };

  React.useEffect(() => {
    updateCols();
    window.addEventListener('resize', updateCols);
    return () => {
      window.removeEventListener('resize', updateCols);
    };
  }, []);

  return (
    <div
      style={{ gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))` }}
      className="grid transition-all duration-300 transform gap-4 justify-items-center"
    >
      <SkeletonProduct scroll={false}>
        {products.map((product) => (
          <CardProduct
            key={`cardProduct${product.id}`}
            product={product}
            scroll={false}
          />
        ))}
      </SkeletonProduct>
    </div>
  );
};

export default ListProduct;
