import {
  DeliveryType,
  HelpIcon,
  PaymentMethodType,
  Toast,
  WherePaymentType,
  currencyFormat,
} from '@onbeefapp/constants'
import * as React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import noAvatar from '../../../apps/catalogo/src/assets/images/noAvatar.png'
import FormCheckout from '../../../apps/catalogo/src/components/Forms/FormCheckout'
import HeaderBar from '../../../apps/catalogo/src/components/Headers/HeaderBar'
import OpenForOrdersModal from '../../../apps/catalogo/src/components/Modals/OpenForOrdersModal'
import Skeleton from '../../../apps/catalogo/src/components/Skeleton/Skeleton'
import { OrderDetailsContext } from '../../../apps/catalogo/src/contexts/OrderDetailsContext'
import { TrackingContext } from '../../../apps/catalogo/src/contexts/TrackingContext'
import { useSlug } from '../../../apps/catalogo/src/hooks/useSlug'
import { useMerchantCache } from '../../../apps/catalogo/src/queries/home'
import { useCartStore } from '../../../apps/catalogo/src/stores/cart'
import { useComplementsStore } from '../../../apps/catalogo/src/stores/complements'
import { useMerchantStore } from '../../../apps/catalogo/src/stores/merchant'
import { useOrderDetails } from '../../../apps/catalogo/src/stores/order-details'
import {
  getMarginTax,
  showCouponDiscount,
} from '../../../apps/catalogo/src/utils/CartUtils'
import { useAuthStore } from '../../../apps/catalogo/src/stores/login'
import { useGetSchedulings } from '../../../apps/catalogo/src/queries/order'
import Tooltip from '../tooltip'
import cx from 'classnames'
import { useGetLinkOrderDetails } from '../../../apps/catalogo/src/queries/checkout-link'
import { useUserStore } from '../../../apps/catalogo/src/stores/user'
import { getUserClub } from '../../../apps/catalogo/src/utils/ClubUtils'

const Checkout: React.FC = () => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const user = useAuthStore((state) => state.user)
  const clearUser = useUserStore((state) => state.clear)
  const {
    clearCart,
    products,
    coupon,
    isCheckoutLink,
    setIsCheckoutLink,
    setLinkOrderDetails,
  } = useCartStore((state) => ({
    clearCart: state.clearCart,
    products: state.products,
    coupon: state.coupon,
    isCheckoutLink: state.isCheckoutLink,
    setIsCheckoutLink: state.setIsCheckoutLink,
    setLinkOrderDetails: state.setLinkOrderDetails,
  }))
  const { clearMerchantIdComplements } = useComplementsStore((state) => ({
    clearMerchantIdComplements: state.clearMerchantIdComplements,
  }))

  const {
    setDeliveryInstructions,
    deliveryType,
    setWherePaymentType,
    setSelectedPaymentMethod,
  } = useOrderDetails((state) => {
    return {
      setDeliveryInstructions: state.setDeliveryInstructions,
      deliveryType: state.deliveryType,
      setWherePaymentType: state.setWherePaymentType,
      setSelectedPaymentMethod: state.setSelectedPaymentMethod,
    }
  })

  const params = useParams()
  const { slug } = useSlug()

  const { isLoading: isLoadingMerchant } = useMerchantCache(slug)
  const navigate = useNavigate()
  const { merchant, clubs, loyalty_programme } = useMerchantStore((state) => ({
    merchant: state.merchant,
    clubs: state.clubs,
    loyalty_programme: state.loyalty_programme,
  }))

  // getting scheduling data
  const { data } = useGetSchedulings(merchant?.id)
  const {
    frete,
    withFreteGratisDiscount,
    subtotalFormatted,
    valueFeesFormatted,
    totalWithFees,
    withFees,
    subtotal,
    subtotalWithDiscount,
    hasProductPerKilo,
    discountFormatted,
    clubDiscountAvailable,
    hasClubDiscount,
    userClubDiscount,
    userClubDiscountFormatted,
    totalClubDiscountFormatted,
    loyaltyRewardFormatted,
    loyaltyGiftFormatted,
    marginValue,
  } = React.useContext(OrderDetailsContext)

  const { selectedPaymentMethod } = useOrderDetails((state) => ({
    selectedPaymentMethod: state.selectedPaymentMethod,
  }))

  const { mutateAsync: getLinkOrderDetails } = useGetLinkOrderDetails()

  const { track } = React.useContext(TrackingContext)

  const handleClearCartClick = () => {
    setDeliveryInstructions('')
    track(`OnCustomerAtCheckoutCartClear`, {})
    track(`OnCustomerLeaveCheckout`, {})
    clearCart()
    clearMerchantIdComplements()
    navigate(`/`)
    Toast.success('Sua sacola está vazia')
  }

  React.useEffect(() => {
    const run = async () => {
      const { id, code } = params
      if (id && code) {
        try {
          const order = await getLinkOrderDetails({ orderID: id, code: code })
          if (order) {
            setLinkOrderDetails(order)
            setIsCheckoutLink(true)
            setWherePaymentType(WherePaymentType.APP)
            setSelectedPaymentMethod(undefined)
            clearUser()
          }
        } catch (error) {
          setLinkOrderDetails(undefined)
          setIsCheckoutLink(false)
          Toast.error(
            'Link de pagamento inválido, entre em contato com a loja!'
          )
          navigate('/', { replace: true })
        }
      } else {
        setLinkOrderDetails(undefined)
        setIsCheckoutLink(false)
      }
    }
    run()
  }, [])

  const activeClub = isAuthenticated ? getUserClub() : null

  return (
    <React.Fragment>
      <div className="p-2">
        <div className="fixed top-0 z-[9999] pt-3 pr-[17px] bg-[#f5f5f5] pb-px transition-all duration-300 ease-in-out w-full">
          {/* HeaderBar */}
          <HeaderBar isCheckout={true}>
            <div
              className={`text-sm font-normal text-center ${
                Object.values(products).length <= 0 || isCheckoutLink
                  ? 'w-full'
                  : 'ml-8'
              }`}
            >
              {isCheckoutLink ? 'Link de pagamento' : 'Sacola'}
            </div>
            {!isCheckoutLink && Object.values(products).length > 0 && (
              <button
                className="text-xs font-semibold text-[#d90016] text-center"
                onClick={handleClearCartClick}
              >
                Limpar
              </button>
            )}
          </HeaderBar>
        </div>

        <div className="flex flex-col space-y-1 items-start justify-start mt-[55px] px-1">
          <div
            className={cx(
              'flex flex-row justify-start  border-b-[1px] pb-2 space-x-2',
              isCheckoutLink ? 'items-center' : 'items-start'
            )}
          >
            <Skeleton
              className="w-10 h-10 md:h-14 md:w-14"
              borderRadius="9999px"
              isLoading={isLoadingMerchant}
            >
              <img
                className="w-10 h-10 rounded-full md:h-14 md:w-14"
                src={merchant?.logo || noAvatar}
                alt="Logotipo"
                loading="lazy"
              />
            </Skeleton>
            <div className="flex flex-col items-start justify-start space-y-1">
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-48 h-4 rounded-lg"
              >
                <div
                  className="text-sm font-normal text-left"
                  onClick={() => {
                    track(`OnCustomerLeaveCheckout`, {})
                    track(`OnCustomerViewMerchantDetails`, {})
                    navigate('details')
                  }}
                >
                  {merchant?.name}
                </div>
              </Skeleton>

              {!isCheckoutLink && (
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-32 h-4 rounded-lg"
                >
                  <button
                    className="text-xs font-bold text-[#d90016] text-left"
                    onClick={() => {
                      track(`OnCustomerLeaveCheckout`, {})
                      navigate(`/`)
                    }}
                  >
                    Adicionar mais itens
                  </button>
                </Skeleton>
              )}
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full space-y-1 border-b-[1px] pb-2">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-32 h-4 rounded-lg"
            >
              <div className="text-sm font-semibold text-left mb-2">
                Resumo de valores
              </div>
            </Skeleton>

            <div className="flex flex-row justify-between text-xs font-normal text-left w-full">
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-16 h-4 rounded-lg"
              >
                <div>Subtotal</div>
              </Skeleton>
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-12 h-4 rounded-lg"
              >
                {subtotalFormatted}
              </Skeleton>
            </div>
            {deliveryType === DeliveryType.DELIVERY && (
              <div className="flex flex-row justify-between text-xs text-[#787878] font-normal text-left w-full">
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-20 h-4 rounded-lg"
                >
                  <div>Taxa de entrega</div>
                </Skeleton>
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-12 h-4 rounded-lg"
                >
                  <div>
                    {withFreteGratisDiscount ? (
                      <>
                        <s>{frete}</s>{' '}
                        <span className="text-green-600">Grátis</span>
                      </>
                    ) : (
                      frete
                    )}
                  </div>
                </Skeleton>
              </div>
            )}
            {subtotal !== subtotalWithDiscount && coupon && (
              <div className="flex flex-row justify-between text-xs text-green-600 font-normal text-left w-full">
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-20 h-4 rounded-lg"
                >
                  <div>Desconto (cupom)</div>
                </Skeleton>
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-12 h-4 rounded-lg"
                >
                  <div>
                    {coupon
                      ? showCouponDiscount(coupon, subtotal - userClubDiscount)
                      : discountFormatted}
                  </div>
                </Skeleton>
              </div>
            )}
            {hasClubDiscount && (
              <div className="flex flex-row justify-between text-xs text-green-600 font-normal text-left w-full">
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-10 h-4 rounded-lg"
                >
                  <div>Desconto do clube</div>
                </Skeleton>

                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-12 h-4 rounded-lg"
                >
                  <div>{userClubDiscountFormatted}</div>
                </Skeleton>
              </div>
            )}
            {loyaltyRewardFormatted && (
              <div className="flex flex-row justify-between text-[12px] text-green-600 font-normal text-left w-full">
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-10 h-4 rounded-lg"
                >
                  <div>{loyalty_programme?.name}</div>
                </Skeleton>

                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-12 h-4 rounded-lg"
                >
                  <div>{loyaltyRewardFormatted}</div>
                </Skeleton>
              </div>
            )}
            {loyaltyGiftFormatted && (
              <div className="flex flex-row justify-between text-[12px] text-green-600 font-normal text-left w-full">
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-10 h-4 rounded-lg"
                >
                  <div>{loyalty_programme?.name} para novos clientes</div>
                </Skeleton>

                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-12 h-4 rounded-lg"
                >
                  <div>{loyaltyGiftFormatted}</div>
                </Skeleton>
              </div>
            )}
            {(selectedPaymentMethod ===
              PaymentMethodType.CHECKOUT_CREDIT_CARD ||
              selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX) &&
              withFees && (
                <div className="flex flex-row justify-between text-xs text-[#787878] font-normal text-left w-full">
                  <Skeleton
                    isLoading={isLoadingMerchant}
                    className="w-20 h-4 rounded-lg"
                  >
                    <div>Taxa de serviço</div>
                  </Skeleton>
                  <Skeleton
                    isLoading={isLoadingMerchant}
                    className="w-12 h-4 rounded-lg"
                  >
                    <div>{valueFeesFormatted}</div>
                  </Skeleton>
                </div>
              )}
            {(marginValue || getMarginTax() > 0) && (
              <div className="flex flex-row justify-between text-[12px] text-[#787878] font-normal text-left w-full">
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-20 h-4 rounded-lg"
                >
                  <div>Margem de variação de peso</div>
                </Skeleton>
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-12 h-4 rounded-lg"
                >
                  <div>
                    {marginValue != null
                      ? currencyFormat(marginValue)
                      : currencyFormat(getMarginTax())}
                  </div>
                </Skeleton>
              </div>
            )}
            <div className="flex flex-row justify-between text-[12px] font-semibold text-left w-full">
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-10 h-4 rounded-lg"
              >
                <div className="flex items-center">
                  <span className="mr-1">{`Total${
                    hasProductPerKilo ? ' estimado' : ''
                  }`}</span>
                  {hasProductPerKilo && (
                    <Tooltip text="O valor dos produtos por peso pode variar de acordo com o peso medido no envio. Estabelecemos essa margem para garantir que você não pague nada além do combinado. Se o peso final for menor que o inicialmente cobrado, faremos o estorno da diferença.">
                      <HelpIcon className="text-gray-400" />
                    </Tooltip>
                  )}
                </div>
              </Skeleton>

              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-12 h-4 rounded-lg"
              >
                <div>{currencyFormat(totalWithFees)}</div>
              </Skeleton>
            </div>
          </div>
        </div>

        {clubs && clubs.length > 0 && clubDiscountAvailable && (
          <div className="flex flex-col shadow-md p-4 items-center space-y-3">
            <Skeleton
              className="w-full h-9 rounded-lg"
              isLoading={isLoadingMerchant}
            >
              <div className="w-full flex flex-col items-center justify-center space-y-1 text-green-600">
                {user && activeClub ? (
                  <Link to="/club" className="underline">
                    {user.name} - Membro VIP {activeClub.club_name}
                  </Link>
                ) : (
                  <div className="w-full flex flex-col items-center space-y-2 text-sm">
                    <button
                      type="button"
                      className="w-full px-2 py-1 border border-green-600 rounded-lg bg-transparent"
                    >
                      <Link to="/club" state={{ goBack: '/checkout' }}>
                        Seja membro e economize {totalClubDiscountFormatted}{' '}
                        nessa compra!
                      </Link>
                    </button>
                    {!isAuthenticated && (
                      <Link
                        to="/login"
                        state={{ goBack: '/checkout' }}
                        className="underline font-bold"
                      >
                        Já sou membro
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </Skeleton>
          </div>
        )}
      </div>
      {data && data?.status === 'ACTIVE' && <OpenForOrdersModal />}
      <FormCheckout
        setExtensionCustomCouponCode={(code: string | undefined) => {
          //console.log('setExtensionCustomCouponCode', code)
        }}
        setExtensionCustomDeliveryFee={(fee: number | string | undefined) => {
          //console.log('setExtensionCustomDeliveryFee', code)
        }}
      />
    </React.Fragment>
  )
}

export default Checkout
