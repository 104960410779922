import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import Skeleton from '../Skeleton/Skeleton';
import cx from 'classnames';

type CollapsibleItemProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  active?: boolean;
  withoutArrow?: boolean;
  hidden?: boolean;
  onClick?: () => void;
};

const CollapsibleItem: React.FC<CollapsibleItemProps> = ({
  title,
  children,
  active = false,
  withoutArrow = false,
  hidden = false,
  onClick,
}) => {
  const isOpen = active;
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  return (
    <div className={cx('mb-2', hidden ? 'hidden' : '')}>
      <Skeleton isLoading={isLoading} className="w-full h-[55px] rounded-lg">
        <div
          className="flex items-center rounded-md cursor-pointer"
          onClick={() => {
            if (!withoutArrow) {
              onClick && onClick();
            }
          }}
        >
          <div className="flex items-center justify-between w-full px-4 py-2 transition-colors duration-200 bg-gray-200 rounded-lg hover:bg-gray-300">
            {/* Left content */}
            <div className="flex items-center">{title}</div>
            {/* Right content */}
            {!withoutArrow && (
              <div className="flex items-center">
                {!isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className={`transition-all transform pl-4 pr-4 ease duration-300 overflow-auto delay-0	 ${
            isOpen ? 'max-h-[70rem] overflow-hidden' : 'max-h-0'
          }`}
        >
          {/* Collapsible content */}
          <div className="mt-2 mb-2">{children}</div>
        </div>
      </Skeleton>
    </div>
  );
};

export default CollapsibleItem;
