import {
  DeliveryType,
  LoyaltyDiscountType,
  LoyaltyRewardStatus,
  Product,
  ProductType,
  currencyFormat,
} from '@onbeefapp/constants';
import React from 'react';
import useCalculateCouponDiscount from '../hooks/useCalculateDiscount';
import {
  useCalculateFees,
  useCalculateFeesFinalValue,
} from '../hooks/useCalculateFeesFinalValue';
import { useGetFreight } from '../queries/order';
import { useCartStore } from '../stores/cart';
import { useAuthStore } from '../stores/login';
import { useMerchantStore } from '../stores/merchant';
import { useOrderDetails } from '../stores/order-details';
import { useUserStore } from '../stores/user';
import { getLoyaltyDiscountReward, getMarginTax } from '../utils/CartUtils';
import { calculatedValue } from '../utils/ProductUtils';
import { prepareProduct } from '../stores/products';

export const OrderDetailsContext = React.createContext<{
  subtotal: number;
  subtotalFormatted?: string;
  subtotalWithDiscount: number;
  discountFormatted?: string;
  rewardProduct?: Product;
  giftProduct?: Product;
  rewardAvailable?: boolean;
  useReward?: boolean;
  loyaltyGiftFormatted?: string;
  loyaltyRewardFormatted?: string;
  clubDiscountAvailable: boolean;
  hasProductPerKilo: boolean;
  hasClubDiscount: boolean;
  userClubDiscount: number;
  userClubDiscountFormatted?: string;
  totalClubDiscountFormatted?: string;
  frete?: string;
  formattedFreteWithDiscount: string;
  freteValue?: number;
  errorRadius: boolean;
  withFreteGratisDiscount: boolean;
  isFreteGratis: boolean;
  isACombinar: boolean;
  total: number;
  totalFormatted?: string;
  totalWithFees: number;
  withFees: boolean;
  isCouponLoading: boolean;
  valueFeesFormatted?: string;
  marginValue: number | undefined;
  idFreight?: string;
  extension?: boolean;
}>({
  subtotal: 0,
  subtotalFormatted: '',
  subtotalWithDiscount: 0,
  discountFormatted: '',
  rewardProduct: undefined,
  giftProduct: undefined,
  rewardAvailable: false,
  useReward: false,
  loyaltyGiftFormatted: '',
  loyaltyRewardFormatted: '',
  clubDiscountAvailable: false,
  hasProductPerKilo: false,
  hasClubDiscount: false,
  userClubDiscount: 0,
  userClubDiscountFormatted: '',
  totalClubDiscountFormatted: '',
  frete: '',
  formattedFreteWithDiscount: '',
  freteValue: 0,
  errorRadius: false,
  withFreteGratisDiscount: false,
  isFreteGratis: false,
  isACombinar: false,
  total: 0,
  totalFormatted: '0',
  totalWithFees: 0,
  withFees: false,
  isCouponLoading: false,
  valueFeesFormatted: '',
  marginValue: undefined,
  idFreight: '',
});

export function OrderDetailsCtxProvider({ children }: React.PropsWithChildren) {
  const user = useAuthStore((state) => state.user);
  const { address } = useUserStore((state) => ({
    address: state.address,
  }));
  const { selectedPaymentMethod, deliveryType } = useOrderDetails((state) => ({
    selectedPaymentMethod: state.selectedPaymentMethod,
    deliveryType: state.deliveryType,
  }));
  const { merchantCache, merchant } = useMerchantStore((state) => ({
    merchantCache: state.merchantCache,
    merchant: state.merchant,
  }));

  const {
    products,
    coupon,
    deliveryTime,
    refreshClubPricing,
    isCheckoutLink,
    linkOrderDetails,
  } = useCartStore((state) => ({
    products: state.products,
    coupon: state.coupon,
    deliveryTime: state.deliveryTime,
    refreshClubPricing: state.refreshClubPricing,
    isCheckoutLink: state.isCheckoutLink,
    linkOrderDetails: state.linkOrderDetails,
  }));

  const userReward =
    user?.loyalty_programmes && user?.loyalty_programmes[0]?.reward;
  const rewardAvailable =
    userReward &&
    userReward.redemption.status === LoyaltyRewardStatus.TO_RESCUE;
  const useReward = !coupon && rewardAvailable;

  const userGift =
    user?.loyalty_programmes && user?.loyalty_programmes[0]?.gift;
  const giftAvailable =
    userGift && userGift.redemption.status === LoyaltyRewardStatus.TO_RESCUE;

  const subtotal = Object.values(products)
    .map((product) => product.calculatedValue ?? 0)
    .reduce((acc, value) => acc + value, 0);

  const addressPayload = address
    ? {
        ...address,
        latitude: address?.latitude || null,
        longitude: address?.longitude || null,
      }
    : undefined;
  const { data: freight } = useGetFreight(
    subtotal,
    merchant?.id ?? '',
    addressPayload,
  );

  const idFreight = freight?.id;

  const errorRadius =
    freight?.result == false &&
    freight?.message === 'Esta empresa não atua neste raio.';

  const freteWithDiscount =
    freight?.freight && deliveryTime?.discount_amount
      ? freight?.freight - deliveryTime.discount_amount
      : freight?.freight ?? 0;

  const formattedFreteWithDiscount = currencyFormat(
    coupon && coupon.type === 'DELIVERY_FREE' ? 0 : freteWithDiscount,
  ) as string;

  const frete =
    deliveryType === DeliveryType.WITHDRAWAL
      ? 'Frete Grátis'
      : freight?.freight == null || freight?.freight.toString() === '0'
        ? freight?.type
        : '' + currencyFormat(freteWithDiscount);

  const withFreteGratisDiscount =
    coupon?.type === 'DELIVERY_FREE' ||
    (!coupon &&
      !!rewardAvailable &&
      userReward?.discount?.type === LoyaltyDiscountType.DELIVERY_FREE) ||
    (!!giftAvailable &&
      userGift.discount?.type === LoyaltyDiscountType.DELIVERY_FREE);

  const isFreteGratis = frete === 'Frete Grátis' || withFreteGratisDiscount;

  const isACombinar = frete === 'À Combinar';

  const hasClubDiscount = Object.values(products).some(
    (p) => p.hasClubDiscount,
  );
  const userClubDiscount = Object.values(products).reduce((acc, cur) => {
    if (cur.hasClubDiscount) {
      if (!cur.clubPrice || !cur.totalAmount) return acc;
      const v =
        calculatedValue(cur, cur.quantity, cur.product_type, true) -
        cur.clubPrice * cur.totalAmount;
      return acc + v;
    }
    return acc;
  }, 0);
  const totalClubDiscount = Object.values(products).reduce((acc, cur) => {
    if (!cur.clubPrice || !cur.totalAmount) return acc;
    const v =
      calculatedValue(cur, cur.quantity, cur.product_type, true) -
      cur.clubPrice * cur.totalAmount;
    return acc + v;
  }, 0);
  const clubDiscountAvailable = totalClubDiscount > 0;

  const hasProductPerKilo = Boolean(
    Object.values(products).find((p) => p.product_type === ProductType.KG),
  );

  let subtotalWithDiscount = subtotal - userClubDiscount;
  const { result, isLoading } =
    useCalculateCouponDiscount(subtotalWithDiscount);

  let loyaltyRewardFormatted: string | undefined;
  let loyaltyGiftFormatted: string | undefined;
  let rewardProduct: Product | undefined;
  let giftProduct: Product | undefined;

  if (coupon) {
    subtotalWithDiscount = result ?? coupon.amount;
  } else if (rewardAvailable) {
    // loyalty
    if (userReward.discount) {
      const { value, formatted } = getLoyaltyDiscountReward(
        userReward.discount,
        subtotalWithDiscount,
      );
      subtotalWithDiscount -= value;
      loyaltyRewardFormatted = formatted;
    } else if (userReward.product) {
      rewardProduct = prepareProduct(userReward.product);
      loyaltyRewardFormatted = `Brinde ${userReward.product.name}`;
    }
  }

  if (giftAvailable) {
    if (userGift.discount) {
      const { value, formatted } = getLoyaltyDiscountReward(
        userGift.discount,
        subtotalWithDiscount,
      );
      subtotalWithDiscount -= value;
      loyaltyGiftFormatted = formatted;
    } else if (userGift.product) {
      giftProduct = prepareProduct(userGift.product);
      loyaltyGiftFormatted = `Brinde ${userGift.product.name}`;
    }
  }

  const total =
    subtotalWithDiscount +
    (isFreteGratis || (coupon && coupon.type === 'DELIVERY_FREE')
      ? 0
      : freteWithDiscount) +
    getMarginTax();

  const totalWithFees = useCalculateFeesFinalValue(
    merchantCache,
    total,
    selectedPaymentMethod,
  );

  const valueFees = useCalculateFees(
    merchantCache,
    total,
    selectedPaymentMethod,
  );

  const withFees = valueFees !== 0;

  React.useEffect(() => {
    refreshClubPricing();
  }, [user]);

  return (
    <OrderDetailsContext.Provider
      value={
        isCheckoutLink && linkOrderDetails
          ? {
              subtotalFormatted: currencyFormat(
                linkOrderDetails.formatted.subtotal,
              ),
              subtotal: linkOrderDetails.formatted.subtotal,
              subtotalWithDiscount:
                linkOrderDetails.formatted.subtotal_with_discount,
              discountFormatted: currencyFormat(
                linkOrderDetails.formatted.subtotal -
                  linkOrderDetails.formatted.subtotal_with_discount,
              ),
              giftProduct: linkOrderDetails.formatted.gift_product || undefined,
              rewardProduct:
                linkOrderDetails.formatted.reward_product || undefined,
              rewardAvailable: false,
              useReward: false,
              loyaltyGiftFormatted:
                linkOrderDetails.formatted.loyalty_gift_formatted || undefined,
              loyaltyRewardFormatted:
                linkOrderDetails.formatted.loyalty_reward_formatted ||
                undefined,
              clubDiscountAvailable: false,
              hasProductPerKilo:
                linkOrderDetails.formatted.has_product_per_kilo,
              hasClubDiscount: linkOrderDetails.formatted.has_club_discount,
              userClubDiscount: linkOrderDetails.formatted.user_club_discount,
              userClubDiscountFormatted: currencyFormat(
                linkOrderDetails.formatted.user_club_discount,
              ),
              totalClubDiscountFormatted: undefined,
              frete: linkOrderDetails.formatted.frete,
              freteValue: linkOrderDetails.formatted.frete_value,
              formattedFreteWithDiscount: currencyFormat(
                linkOrderDetails.formatted.frete_with_discount,
              )!,
              errorRadius: false,
              withFreteGratisDiscount:
                linkOrderDetails.formatted.with_frete_gratis_discount,
              isFreteGratis:
                linkOrderDetails.formatted.frete === 'Frete Grátis' ||
                linkOrderDetails.formatted.with_frete_gratis_discount,
              isACombinar: linkOrderDetails.formatted.frete === 'À Combinar',
              total: linkOrderDetails.formatted.total,
              totalFormatted: currencyFormat(linkOrderDetails.formatted.total),
              totalWithFees: linkOrderDetails.formatted.total_with_fees,
              isCouponLoading: false,
              valueFeesFormatted: currencyFormat(
                linkOrderDetails.formatted.values_fees,
              ),
              withFees: linkOrderDetails.formatted.values_fees !== 0,
              marginValue: linkOrderDetails.formatted.fee_margin_value,
              idFreight,
            }
          : {
              subtotalFormatted: currencyFormat(subtotal),
              subtotal,
              subtotalWithDiscount,
              discountFormatted: currencyFormat(
                subtotal - subtotalWithDiscount,
              ),
              giftProduct,
              rewardProduct,
              rewardAvailable,
              useReward,
              loyaltyGiftFormatted,
              loyaltyRewardFormatted,
              clubDiscountAvailable,
              hasProductPerKilo,
              hasClubDiscount,
              userClubDiscount,
              userClubDiscountFormatted: currencyFormat(userClubDiscount),
              totalClubDiscountFormatted: currencyFormat(totalClubDiscount),
              frete,
              freteValue: freight?.freight,
              formattedFreteWithDiscount,
              errorRadius,
              withFreteGratisDiscount,
              isFreteGratis,
              isACombinar,
              total,
              totalFormatted: currencyFormat(total),
              totalWithFees,
              isCouponLoading: isLoading,
              valueFeesFormatted: currencyFormat(valueFees),
              withFees,
              idFreight,
              marginValue: undefined,
            }
      }
    >
      {children}
    </OrderDetailsContext.Provider>
  );
}
