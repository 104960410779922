import {
  LoyaltyDiscountType,
  ProductType,
  currencyFormat,
} from '@onbeefapp/constants';
import { ValidatedCoupon } from '../queries/coupon/types';
import { ProductCartType, useCartStore } from '../stores/cart';
import { useMerchantStore } from '../stores/merchant';
import { LoyaltyDiscount } from '../queries/loyalty/types';
import { calculatedValue } from './ProductUtils';
import { useComplementsStore } from '../stores/complements';
import { getCutsQuantity } from './CutsUtils';

export function showCouponDiscount(coupon: ValidatedCoupon, subtotal: number) {
  return {
    PERCENTAGE: currencyFormat(subtotal - coupon.amount),
    FIXED: currencyFormat(subtotal - coupon.amount),
    DELIVERY_FREE: 'Frete grátis',
  }[coupon.type];
}

export function getTotalCart() {
  const products = useCartStore.getState().products;

  if (Object.values(products).length === 0) return 0;

  const subtotal = Object.values(products)
    .map((product) => product.calculatedValue ?? 0)
    .reduce((acc, value) => acc + value, 0);
  return subtotal;
}

export function getMarginTax() {
  const products = useCartStore.getState().products;
  const marginPercent =
    useMerchantStore.getState().merchant?.config?.margin_price_per_kilo;

  if (Object.values(products).length === 0) return 0;

  const totalValue = Object.values(products)
    .filter((product) => product.product_type === ProductType.KG)
    .map((product) =>
      calculatedValue(
        product,
        product.quantity ?? 0,
        product.product_type,
        true,
      ),
    )
    .reduce((acc, value) => acc + value, 0);

  if (!marginPercent) return 0;

  return totalValue * (marginPercent / 100);
}

export function getRestFreeFreigth(): {
  restFreeFreigth: number;
  percentage: string;
} {
  const delivery = useMerchantStore.getState().delivery;

  if (delivery === undefined)
    return {
      restFreeFreigth: 0,
      percentage: '0%',
    };

  if (delivery?.price_min_order_delivery_free === 0)
    return {
      restFreeFreigth: 0,
      percentage: '0%',
    };

  return {
    restFreeFreigth:
      (delivery?.price_min_order_delivery_free ?? 0) - getTotalCart(),
    percentage: `${(
      (getTotalCart() / (delivery?.price_min_order_delivery_free ?? 0)) *
      100
    ).toFixed(0)}%`,
  };
}

export function getLoyaltyDiscountReward(
  discount: LoyaltyDiscount,
  subtotalWithDiscount: number,
): {
  value: number;
  formatted: string;
} {
  let [value, formatted] = [0, ''];
  switch (discount.type) {
    case LoyaltyDiscountType.AMOUNT:
      value = discount.value;
      formatted = `-${currencyFormat(discount.value)}`;
      break;
    case LoyaltyDiscountType.PERCENTAGE:
      value = subtotalWithDiscount * (discount.value / 100);
      formatted = `-${currencyFormat(value)}`;
      break;
    case LoyaltyDiscountType.DELIVERY_FREE:
      formatted = 'Frete grátis';
      break;
  }
  return {
    value,
    formatted,
  };
}

export function getFinalQuantity(product: ProductCartType): number {
  if (product.type === ProductType.UNIT || product.type === ProductType.KIT) {
    return product.quantity;
  }

  const quantityComplements = useComplementsStore
    .getState()
    .getSumQuantityComplementsCuts(product.id);
  let quantityPer100 = 0;
  if (product.estimated_weight) {
    quantityPer100 = product.quantity * product.estimated_weight;
  }
  const quantityCuts = getCutsQuantity(product.selectedCuts);

  if (quantityComplements > 0) {
    return quantityComplements;
  }

  if (quantityCuts > 0) {
    return quantityCuts;
  }

  return quantityPer100;
}
