import { Address } from './../../../../../packages/constants/src/utils/AddressUtils';
import api from '../../services/api';
import { useAuthStore } from '../../stores/login';
import { authPhone } from '../login';
import {
  CreateOrderResponse,
  DraftPayload,
  FreightResponse,
  OrderDetailsResponse,
  OrderPayload,
  SchedulingsResponse,
  StatusPixResponse,
} from './types';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

async function getStatusPix(
  ctx: QueryFunctionContext,
): Promise<AxiosResponse<StatusPixResponse>> {
  const [, id, code] = ctx.queryKey;
  const response = await api.get(`/all/customers/orders/${id}/status/${code}`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    },
  });
  return response;
}

async function createOrder({
  payload,
  extension,
}: {
  payload: OrderPayload | DraftPayload;
  extension?: boolean;
}) {
  try {
    let accessToken = localStorage.getItem('access_token') || '';

    const { data } = await axios.post<CreateOrderResponse>(
      api.getUri() + '/customers/orders',
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          Version: '1.0',
        },
      },
    );

    if (!extension) {
      const authPhoneData = await authPhone({
        phone: payload.phone,
        name: payload.name,
        taxpayer_id_number: payload.taxpayer_id_number,
      });
      useAuthStore.getState().setAuthState(true, authPhoneData);
      accessToken = authPhoneData.access_token;
    }

    return data;
  } catch (error) {
    throw error;
  }
}
async function getFreight(ctx: QueryFunctionContext) {
  const [, id, amount, address] = ctx.queryKey;
  if (!id || !amount || !address) return null;
  const { data } = await api.post<FreightResponse>(
    '/customers/orders/freight/' + id,
    {
      amount: amount,
      address: address,
    },
  );
  return data;
}

async function getSchedulings(ctx: QueryFunctionContext) {
  const [, merchant_id] = ctx.queryKey;
  if (!merchant_id) return;
  const { data } = await api.get<SchedulingsResponse>(
    `/all/merchants/${merchant_id}/orders/schedulings`,
  );
  return data;
}

export function useCreateOrder() {
  return useMutation(['createOrder'], createOrder);
}

export function useGetFreight(
  amount: number,
  id = '',
  address: Address | undefined,
) {
  return useQuery(['getFreight', id, amount, address], getFreight);
}

async function getOrderDetails(ctx: QueryFunctionContext) {
  const [, id] = ctx.queryKey;
  if (!id) return null;
  const { data } = await api.get<OrderDetailsResponse>(
    '/customers/orders/' + id,
  );
  return data;
}

export function useGetOrderDetails(id?: string) {
  return useQuery(['getOrderDetails', id], getOrderDetails, {
    refetchOnWindowFocus: true,
    staleTime: 2000000,
  });
}

export function useGetStatusPix(id: string, code: string) {
  return useQuery(['getStatusPix', id, code], getStatusPix);
}

export function useGetSchedulings(merchant_id?: string) {
  return useQuery(['getSchedulings', merchant_id], getSchedulings);
}
