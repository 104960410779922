import CardProduct from '../../components/Cards/CardProduct';
import SkeletonProduct from '../../components/Skeleton/SkeletonProduct';
import { Product } from '@onbeefapp/constants';
import CardViewAllProducts from '../Cards/CardViewAllProducts';
import * as React from 'react';

interface ListScrollProductProps {
  products: Product[];
  id: string;
  productsLimit: number;
  productsMore: number;
  slug?: string;
}

const ListScrollProduct: React.FC<ListScrollProductProps> = ({
  products,
  id,
  productsLimit,
  productsMore,
  slug,
}) => {
  //Listar produtos em scroll horizontal

  return (
    <div
      className="space-x-3 flex snap-x snap-mandatory w-full mx:auto overflow-x-scroll overflow-y-hidden"
      id={`scroll${id}`}
    >
      <SkeletonProduct>
        {products.slice(0, productsLimit).map((product) => (
          <CardProduct key={`cardProduct${product.id}`} product={product} />
        ))}
        {productsMore > 0 && (
          <CardViewAllProducts quantity={productsMore} category_slug={slug} />
        )}
      </SkeletonProduct>
    </div>
  );
};

export default ListScrollProduct;
