import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import api from '../../services/api';
import {
  CacheResponse,
  MerchantNextSchedulesResponse,
  MerchantSchedulesResponse,
  TagsResponse,
} from './types';
import { useCartStore } from '../../stores/cart';
import axios from 'axios';

async function merchantCache(ctx: QueryFunctionContext) {
  const [, slug] = ctx.queryKey;

  if (!slug) {
    return;
  }

  const isExtension = useCartStore.getState().isExtension;

  if (isExtension) return;

  const { data: merchantDataSlug } = await api.get<{
    id: string;
    link: string;
    slug: string;
  }>(`/all/merchants/${slug}`);

  const { data } = await axios.get<CacheResponse>(
    merchantDataSlug.link + '?nocache=true',
  );

  return data;
}

async function merchantSchedules(ctx: QueryFunctionContext) {
  const [, merchantId] = ctx.queryKey;
  if (!merchantId) return;
  const { data } = await api.get<MerchantSchedulesResponse>(
    `/all/merchants/schedules/${merchantId}`,
  );
  return data;
}

async function merchantNextSchedules(ctx: QueryFunctionContext) {
  const [, merchantId] = ctx.queryKey;
  if (!merchantId) return;
  const { data } = await api.get<MerchantNextSchedulesResponse>(
    `/all/merchants/next/schedule/${merchantId}`,
  );
  return data;
}

async function tags() {
  const { data } = await api.get<TagsResponse>('/tags');
  return data;
}

export function useMerchantCache(slug: string | undefined) {
  return useQuery(['merchantCache', slug], merchantCache, {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 2000000,
  });
}

export function useMerchantSchedules(merchantId: string | undefined) {
  return useQuery(['merchantSchedules', merchantId], merchantSchedules, {
    refetchOnWindowFocus: true,
    staleTime: 2000000,
  });
}

export function useMerchantNextSchedules(merchantId: string | undefined) {
  return useQuery(
    ['merchantNextSchedules', merchantId],
    merchantNextSchedules,
    {
      refetchOnWindowFocus: true,
      staleTime: 2000000,
    },
  );
}

export function useTags() {
  return useQuery(['tags'], tags);
}
