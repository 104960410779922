import { DeliveryDetails, PaymentMethodType } from '@onbeefapp/constants';
import * as React from 'react';
import PixImage from '../../assets/images/pix.png';
import {
  OptionValue,
  OptionsSetter,
} from '../../pages/PaymentMethods/Options/OptionsApp';
import { useMerchantStore } from '../../stores/merchant';
import CardMethodPayment from '../Cards/CardMethodPayment';
import { useCartStore } from '../../stores/cart';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';

const ListAppMethods: React.FC<{
  showPix?: boolean;
  setter: OptionsSetter;
  selectedValue: OptionValue;
}> = ({ setter, selectedValue, showPix = true }) => {
  const { isACombinar } = React.useContext(OrderDetailsContext);

  const {
    activePix,
    activeCreditCard,
    activeCheckoutLink,
    payment_methods,
    merchant,
  } = useMerchantStore((state) => ({
    merchant: state.merchant,
    activePix: state.activePix,
    activeCreditCard: state.activeCreditCard,
    activeCheckoutLink: state.activeCheckoutLink,
    payment_methods: state.payment_methods,
  }));

  const linkOrderDetails = useCartStore((state) => state.linkOrderDetails);
  const isCheckoutLink = useCartStore((state) => state.isCheckoutLink);

  const checkIfWhatsappPixIsActive = Boolean(
    payment_methods?.find(
      (item) =>
        item.name.includes('Whatsapp') && item.pivot && item.pivot.status === 1,
    ),
  );

  const showPixPayment = isACombinar
    ? false
    : isCheckoutLink
      ? linkOrderDetails?.formatted?.payment_methods?.includes('PIX')
      : showPix && activePix;
  const showCreditCardPayment = isACombinar
    ? false
    : isCheckoutLink
      ? linkOrderDetails?.formatted?.payment_methods?.includes('CREDIT_CARD')
      : activeCreditCard;
  const showCheckoutLink = activeCheckoutLink;

  return (
    <React.Fragment>
      <div className={`grid grid-cols-${showPix ? '2' : '1'} gap-2`}>
        {showPixPayment && (
          <div
            className="cursor-pointer"
            onClick={() => {
              setter(PaymentMethodType.CHECKOUT_PIX);
              const element = document.getElementById('checkoutScreen');
              element?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <CardMethodPayment
              slug="CHECKOUT_PIX"
              name="Pix"
              image={PixImage}
              selectedValue={selectedValue}
            />
          </div>
        )}
        {showCreditCardPayment && (
          <div
            className="cursor-pointer"
            onClick={() => {
              setter(PaymentMethodType.CHECKOUT_CREDIT_CARD);
              const element = document.getElementById('checkoutScreen');
              element?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <CardMethodPayment
              slug="CHECKOUT_CREDIT_CARD"
              name="Cartão de Crédito"
              image={
                'https://2tspace.sfo3.cdn.digitaloceanspaces.com/metodos_pagamento%2Fcredit-card.png'
              }
              selectedValue={selectedValue}
            />
          </div>
        )}
        {showCheckoutLink && (
          <div
            className="cursor-pointer"
            onClick={() => {
              setter(PaymentMethodType.CHECKOUT_LINK);
              const element = document.getElementById('checkoutScreen');
              element?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <CardMethodPayment
              slug="CHECKOUT_LINK"
              name="Link de pagamento"
              image={
                'https://2tspace.sfo3.cdn.digitaloceanspaces.com/metodos_pagamento%2Fcredit-card.png'
              }
              selectedValue={selectedValue}
            />
          </div>
        )}
        {!isCheckoutLink &&
          checkIfWhatsappPixIsActive &&
          merchant?.delivery_details !== DeliveryDetails.UBER && (
            <div
              className="cursor-pointer"
              onClick={() => {
                setter(PaymentMethodType.WHATSAPP_PIX);
                const element = document.getElementById('checkoutScreen');
                element?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <CardMethodPayment
                slug="WHATSAPP_PIX"
                name="Pix via Whatsapp"
                image={PixImage}
                selectedValue={selectedValue}
              />
            </div>
          )}
      </div>
    </React.Fragment>
  );
};

export default ListAppMethods;
