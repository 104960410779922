import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import {
  ComplementItem,
  CutTypes,
  formatGrams,
  ProductType,
  Toast,
} from '@onbeefapp/constants';
import React, { useEffect, useState } from 'react';
import Picker, { PickerValue } from 'react-mobile-picker';
import { useNavigate } from 'react-router-dom';
import { TrackingContext } from '../../contexts/TrackingContext';
import { CutPayload } from '../../queries/order/types';
import { useCartStore } from '../../stores/cart';
import { useComplementsStore } from '../../stores/complements';
import { useDrawerStore } from '../../stores/drawer';
import { useMerchantStore } from '../../stores/merchant';
import { getQuantity } from '../../utils/ComplementUtils';
import { getSelectedCutDetail } from '../../utils/CutsUtils';
import {
  getComments,
  getQuantity as ProductQuantity,
} from '../../utils/ProductUtils';
import CollapsibleItem from '../Collapse/CollapsibleItem';

type DrawerGramsProps = {
  complementDrawer?: boolean;
};

const DrawerGrams: React.FC<DrawerGramsProps> = ({
  complementDrawer = false,
}) => {
  const navigate = useNavigate();
  const [optionGroups, setOptionGroups] = useState<string[]>([]);
  const [qtdGrams, setQtdGrams] = useState(0);
  const { addComplement, deleteComplementItem } = useComplementsStore(
    (state) => ({
      addComplement: state.addComplement,
      deleteComplementItem: state.deleteComplementItem,
    }),
  );
  const {
    isOpen,
    toggleDrawer,
    product,
    cutID,
    complement,
    complementItem,
    key,
  } = useDrawerStore((state) => ({
    isOpen: state.isOpen,
    toggleDrawer: state.toggleDrawer,
    product: state.product,
    cutID: state.cutID,
    complement: state.complement,
    complementItem: state.complementItem,
    key: state.key,
  }));

  const { addCart, products, deleteProduct, removeProduct, addCut, removeCut } =
    useCartStore((state) => ({
      addCart: state.addCart,
      products: state.products,
      deleteProduct: state.deleteProduct,
      removeProduct: state.removeProduct,
      addCut: state.addCut,
      removeCut: state.removeCut,
    }));

  const { isMerchantOpen, schedules } = useMerchantStore((state) => ({
    isMerchantOpen: state.isMerchantOpen,
    schedules: state.schedules,
    merchant: state.merchant,
  }));

  const { quantity: quantityProduct } = ProductQuantity(products, product);

  const { comments } = getComments(product?.id);

  const [openCutComments, setOpenCutComments] = React.useState(false);
  const [cutComments, setCutComments] = React.useState('');

  const { number: totalQuantity, quantity: quantityComplement } = getQuantity(
    product.id,
    complement.id,
    complementItem.id,
  );
  const selectedCut = getSelectedCutDetail(product.id, cutID);

  const { track } = React.useContext(TrackingContext);

  useEffect(() => {
    calculateGrams();
  }, [product, complementItem, cutID]);

  const checkUserLimit = (quantity: number) => {
    if (
      product?.promotion &&
      product?.promotion?.max_buyer &&
      product?.promotion?.max_buyer != 0 &&
      quantity > product?.promotion?.max_buyer
    ) {
      Toast.error(
        `Este produto possui o limite de ${
          product?.promotion?.max_buyer * product.estimated_weight!
        }g por pessoa`,
      );
      return true;
    }
    return false;
  };

  const calculateGrams = () => {
    if (product.product_type === ProductType.KG) {
      const array = [];
      const cut = cutID ? product.cuts.find((cut) => cut.id === cutID) : null;
      const estimated_weight = cut
        ? cut.estimated_weight || product.estimated_weight
        : product.estimated_weight;
      const min = estimated_weight! / product.add_grams!;
      const oldCut = product?.complementsCut?.length > 0;
      for (let i = 0; i < 50; i++) {
        if (oldCut) {
          array[i] = estimated_weight! + product.add_grams! * i;
        } else {
          if (cut && cut.type !== CutTypes.PACKAGE) {
            array[i] = estimated_weight! + product.add_grams! * i;
          } else {
            array[i] = estimated_weight! + estimated_weight! * i;
          }
        }
      }

      setOptionGroups(array.map(String));

      const qtd = min;

      setQtdGrams(array[0] || product.add_grams! * qtd);

      if (selectedCut && selectedCut.quantity > 0) {
        setQtdGrams(selectedCut.quantity);
        return;
      }

      if (totalQuantity > 0 && product.cuts?.length === 0) {
        setQtdGrams(totalQuantity);
        return;
      }

      product.totalAmount && setQtdGrams(product.totalAmount);
    }
  };

  const handleChange = (value: PickerValue, key: string) => {
    const v = parseFloat(value[key]!);
    setQtdGrams(v);
  };

  const handleSelectGrams = () => {
    if (cutID) {
      if (!isMerchantOpen && schedules?.receive_orders_when_closed === false) {
        return Toast.error(
          'Loja fechada, não é possivel adicionar produtos na sacola',
        );
      }

      const quantity = qtdGrams;
      const newQuantity = quantityProduct + quantity;

      const newCut: CutPayload = {
        quantity,
        comments: cutComments,
        id: cutID,
      };
      addCart(product, newQuantity, comments);
      addCut(product.id, newCut);
    } else if (complementDrawer) {
      if (!isMerchantOpen && schedules?.receive_orders_when_closed === false) {
        return Toast.error(
          'Loja fechada, não é possivel adicionar produtos na sacola',
        );
      }

      const quantity = qtdGrams / product.add_grams!;

      const difference = quantity - quantityComplement;
      const newQuantity = quantityProduct + difference;
      const newComplementItem: ComplementItem = {
        ...complementItem,
        quantity: quantity,
        type: product.product_type,
        totalQuantity: qtdGrams,
        items_kg: complement.items_kg,
      };
      addComplement(product.id, complement.id, newComplementItem);
      addCart(product, newQuantity, comments);
      track('OnCustomerAtProductAddComplement', {
        ...newComplementItem,
      });
    } else {
      const quantity = qtdGrams / product.estimated_weight!;

      const over = checkUserLimit(quantity);
      if (over) return;
      addCart(product, quantity, comments);
      track('OnCustomerAtProductAddToCart', {
        ...product,
        quantity,
      });
    }
    toggleDrawer('drawerGrams');
  };

  const handleRemoveProduct = () => {
    deleteProduct(product.id);
    track('OnCustomerAtProductRemoveFromCart', {
      ...product,
    });
    toggleDrawer('drawerGrams');
    Toast.error('Item removido da sua sacola');
  };

  const handleRemoveItem = () => {
    if (cutID) {
      const isProductRemoved = removeCut(product.id, cutID);
      toggleDrawer('drawerGrams');
      if (isProductRemoved) {
        Toast.error('Item removido da sua sacola');
        navigate(-1);
      }
    } else {
      deleteComplementItem(product.id, complement.id, complementItem.id);
      const quantity = quantityProduct - totalQuantity;

      addCart(product, quantity, comments);
      toggleDrawer('drawerGrams');

      if (quantity <= 0) {
        removeProduct(product.id);
        track('OnCustomerAtProductRemoveFromCart', {
          ...product,
        });
        Toast.error('Item removido da sua sacola');

        return navigate(-1);
      } else {
        track('OnCustomerAtProductRemoveComplement', {
          ...complementItem,
        });
      }
    }
  };

  const open = (isOpen && key !== 'drawerGrams') || !isOpen ? false : true;

  const cut = product.cuts?.find((c) => c.id === cutID);

  React.useEffect(() => {
    if (cut) {
      setOpenCutComments(!!selectedCut?.comments);
      setCutComments(selectedCut?.comments || '');
    } else {
      setOpenCutComments(false);
    }
  }, [cutID]);

  return (
    <div>
      <div
        className={`${
          open
            ? 'fixed translate-y-0 z-[9999] bg-black-transparent-medium'
            : 'fixed translate-y-full  z-[-10] bg-transparent'
        }  fixed inset-0 bottom-0 right-0 z-[99998] transition-all duration-0`}
        onClick={() => {
          toggleDrawer('drawerGrams');
        }}
      />
      <div
        className={`${
          open ? 'fixed translate-y-0 z-[9999]' : 'fixed translate-y-full '
        } bottom-0 right-0 p-4 bg-white shadow-lg w-full rounded-t-2xl transition-all duration-300 transform`}
      >
        <div
          className={`translate-y-0 fixed z-[99999] bottom-0 right-0 p-4 bg-white shadow-lg w-full rounded-t-2xl transition-all duration-300 transform
        ${open ? 'translate-y-0' : 'translate-y-full'}`}
        >
          <div className="p-4 text-left text-black">
            {cutID && <article className="font-medium">{cut?.label}</article>}
            <article className="text-sm font-semibold">
              Você gostaria de quantos gramas?
            </article>
            <article className="text-sm font-normal mt-1 flex flex-row items-center gap-1 flex-wrap">
              <span className="font-bold inline-flex flex-row gap-1 items-center">
                <ExclamationCircleIcon className="w-5 h-5" />
                Produtos por kg podem variar na pesagem.
              </span>{' '}
              Faremos o possível para chegar o mais próximo do seu pedido.
            </article>
            <Picker
              allowFullScreen
              allowTransparency
              value={{ grams: String(qtdGrams) }}
              onChange={handleChange}
            >
              <Picker.Column name="grams">
                {optionGroups.map((option, index) => (
                  <Picker.Item key={option} value={String(option)}>
                    <div className="relative w-full items-center justify-center flex flex-row gap-10">
                      <span className="font-medium">{formatGrams(option)}</span>
                      {cut?.type === CutTypes.PACKAGE && (
                        <div className="absolute right-14">
                          = {index + 1} item
                        </div>
                      )}
                    </div>
                  </Picker.Item>
                ))}
              </Picker.Column>
            </Picker>
            {cut != null && (
              <div className="flex flex-col mb-2">
                <CollapsibleItem
                  title={'Deixar instrução extra'}
                  active={openCutComments}
                  onClick={() => setOpenCutComments(!openCutComments)}
                >
                  <textarea
                    onChange={(e) => {
                      setCutComments(e.target.value);
                    }}
                    value={cutComments}
                    className="w-full h-24 p-2 border border-gray-300 rounded-md"
                    placeholder="Ex: Tirar a gordura, cortar em bifes finos, etc."
                  />
                </CollapsibleItem>
              </div>
            )}

            <button
              className="w-full p-2 rounded-lg bg-primary text-contrastText"
              onClick={handleSelectGrams}
            >
              Selecionar {qtdGrams}g
            </button>
            {quantityProduct > 0 && !complementDrawer && !cutID && (
              <button
                className="text-sm font-semibold text-[#d90016] text-center w-full mt-4"
                onClick={handleRemoveProduct}
              >
                Remover produto da sacola
              </button>
            )}

            {((totalQuantity > 0 && complementDrawer) ||
              (cutID && selectedCut)) && (
              <button
                className="text-sm font-semibold text-[#d90016] text-center w-full mt-4"
                onClick={handleRemoveItem}
              >
                Remover item deste produto
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerGrams;
