import {
  MapPinIcon,
  IdentificationIcon,
  StarIcon,
  LockClosedIcon,
  ShoppingCartIcon,
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/24/outline';
import HeaderBar from '../../components/Headers/HeaderBar';
import { useUserStore } from '../../stores/user';
import { ProfileItem } from './ProfileItem';
import { useAuthStore } from '../../stores/login';
import { CrownIcon } from '@onbeefapp/constants';
import { Skeleton } from '@onbeef/components/skeleton';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useNavigate } from 'react-router-dom';
import { useMerchantStore } from '../../stores/merchant';

export const Profile: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  const navigate = useNavigate();

  const user = useUserStore();

  const clubs = useMerchantStore((state) => state.clubs);
  const loyalty = useMerchantStore((state) => state.loyalty_programme);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const userClubs = useAuthStore((state) => state.user?.clubs);
  const logout = useAuthStore((state) => state.logout);

  const handleLogout = () => {
    logout();
  };

  const club = clubs && clubs.length > 0 ? clubs[0] : undefined;
  const userClub = userClubs && userClubs.length > 0 ? userClubs[0] : undefined;

  if (!isAuthenticated) {
    navigate('/');
    return;
  }

  return (
    <div className="w-full h-full p-4 space-y-2">
      <HeaderBar>
        <span className="text-sm font-normal text-center w-full">
          Meu perfil
        </span>
      </HeaderBar>

      <div className="flex flex-col gap-4">
        <Skeleton isLoading={isLoading} className="w-full h-12">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <span className="rounded-full bg-primary w-10 h-10 text-md text-center flex justify-center items-center text-contrastText">
                {user.name.charAt(0).toUpperCase()}
              </span>
              <span className="text-md text-gray-600 font-medium">
                {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
              </span>
            </div>
            <div className="flex items-center">
              <button
                type="button"
                onClick={handleLogout}
                className="text-red-400"
              >
                <ArrowLeftStartOnRectangleIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
        </Skeleton>

        <div className="space-y-2">
          <ProfileItem
            icon={<IdentificationIcon className="w-7" />}
            path="/profile/info"
            title="Meus dados"
            description="Informações da sua conta"
          />
          <ProfileItem
            icon={<LockClosedIcon className="w-7" />}
            path="/profile/password"
            title="Senha"
            description="Alterar senha de acesso"
          />
          <ProfileItem
            icon={<MapPinIcon className="w-7" />}
            path="/profile/address"
            title="Endereço"
            description="Seu endereço de entrega"
          />
          <ProfileItem
            icon={<ShoppingCartIcon className="w-7" />}
            path="/orders"
            title="Meus pedidos"
            description="Seu histórico de pedidos"
          />
          {club && (
            <ProfileItem
              icon={
                <CrownIcon className="w-7 text-black grayscale brightness-0" />
              }
              path={userClub ? '/club' : '/club/preview'}
              state={{ clubName: userClub?.club_name }}
              title="Clubes de benefícios"
              description="Meus clubes de benefícios"
            />
          )}
          {loyalty && (
            <ProfileItem
              icon={<StarIcon className="w-7" />}
              path="/loyalty/detail"
              title="Clube de fidelidade"
              description="Ganhe brindes, descontos e muito mais"
            />
          )}
        </div>
      </div>
    </div>
  );
};
