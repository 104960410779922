import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { TextArea } from '@onbeef/components/input';
import { Stars } from '@onbeef/components/stars';
import { OrderDetails, StatusType } from '@onbeefapp/constants';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRefreshLoyaltyData } from '../queries/loyalty';
import { useGetOrderDetail, useRegisterAssessment } from '../queries/my-orders';
import { CreateOrderResponse } from '../queries/order/types';
import { useAuthStore } from '../stores/login';
import { useMerchantStore } from '../stores/merchant';
import { useGeneratedOrderStore } from '../stores/pix';
import { getWhatsappURL } from '../utils/Whatsapp/WhatsappURL';
import { statusPage } from '../utils/function';
import LoadingSpin from './LoadingSpin';

interface RatingForm {
  text: string;
  stars: number;
}

const StatusPage: React.FC = () => {
  const params = useParams();
  const order_id = params.id ?? '';
  const { merchant } = useMerchantStore((state) => ({
    merchant: state.merchant,
  }));
  const navigate = useNavigate();

  const { mutate, data, isSuccess, isLoading } = useRegisterAssessment();

  const { mutateAsync: refreshLoyaltyData, isLoading: refreshLoading } =
    useRefreshLoyaltyData();

  const { generatedOrder, usedLoyalty } = useGeneratedOrderStore((state) => ({
    generatedOrder: state.generatedOrder,
    usedLoyalty: state.usedLoyalty,
  }));

  const updateLoyalty = useAuthStore((state) => state.updateLoyalty);

  const order = statusPage(
    (generatedOrder[order_id] ?? undefined) as CreateOrderResponse | undefined,
  );

  const methods = useForm<RatingForm>({
    defaultValues: {
      text: '',
      stars: 0,
    },
  });

  const [orderData, setOrderData] = React.useState<OrderDetails | undefined>(
    undefined,
  );

  const { data: dataOrder, isLoading: isLoadingOrder } = useGetOrderDetail(
    params.id,
  );

  const submitRate = ({ stars, text }: RatingForm) => {
    if (!merchant?.id) return;

    mutate({
      type: 'APP',
      merchant_id: merchant.id,
      order_id: order_id,
      rating: stars,
      comments: text,
      motives: [],
    });
  };

  React.useEffect(() => {
    if (data && isSuccess) {
      continueAction();
    }
  }, [data]);

  React.useEffect(() => {
    if (dataOrder) {
      setOrderData(dataOrder);
    }
  }, [dataOrder]);

  const onSubmit = (data: RatingForm) => {
    const { stars, text } = data;
    if (stars !== 0 || text !== '') {
      submitRate(data);
    } else {
      continueAction();
    }
  };

  const handleRefreshLoyalty = async () => {
    const data = await refreshLoyaltyData();
    data && updateLoyalty(data);
  };

  const continueAction = async () => {
    if (
      orderData &&
      generatedOrder[order_id] &&
      order.status === StatusType.SUCCESS
    ) {
      const id = generatedOrder[order_id]?.id ?? '';

      let wppURL = await getWhatsappURL(orderData.merchant.phone, orderData.id);

      if (merchant?.whatsapp_instance) {
        if (
          merchant.whatsapp_instance.is_connected &&
          merchant.whatsapp_instance.config_details.send_orders_to_customers
        ) {
          wppURL =
            'https://api.whatsapp.com/send?phone=+55' +
            encodeURIComponent(
              parseInt(merchant!.phone.replace(/[^0-9]/g, '')),
            ) +
            '&text=' +
            encodeURIComponent('Quero acompanhar meu pedido!');
        }
      }

      if (usedLoyalty) {
        await handleRefreshLoyalty();
      }
      if (merchant?.orders_on_whatsapp) {
        window.location.href = wppURL;
      } else {
        navigate(`/orders/${id}`, { replace: true });
      }
    } else {
      navigate(`/`, { replace: true });
    }
  };

  return (
    <div
      className={`flex items-center bg-white p-5 text-center w-full h-screen`}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="w-full flex flex-col p-2 gap-4 mb-12"
        >
          <div className="flex flex-col w-full items-center justify-center">
            <div className="flex flex-col items-center justify-center">
              {order.status === StatusType.SUCCESS && (
                <CheckCircleIcon className="w-20 h-20 text-green-500" />
              )}
              {order.status === StatusType.ERROR && (
                <XCircleIcon className="w-20 h-20 text-red-500" />
              )}
              <h1 className="my-3 text-2xl font-bold text-center text-gray-700">
                {order.title}
              </h1>
              <p className="text-gray-500 text-sm font-semibold">
                {order?.message}
              </p>
            </div>
            {order.status === StatusType.SUCCESS && (
              <div className="flex flex-col w-full justify-center items-center gap-3">
                <span className="font-base text-2xl text-black">
                  Como avaliaria sua experiência até agora?
                </span>
                <Stars
                  value={methods.watch('stars')}
                  handler={(v) => methods.setValue('stars', v)}
                />
                <TextArea
                  name="text"
                  className="mt-2 w-full h-[100px] text-base resize-none outline-none rounded-md px-2 py-1 border border-gray-300"
                  placeholder="Escreva aqui se viu algo que podemos melhorar"
                />
                <span>
                  Por favor, considere avaliar, essa pesquisa é 100% anônima e
                  sua opinião é muito importante
                </span>
              </div>
            )}
            <button
              type="submit"
              className="w-full rounded-lg bg-primary p-2 text-contrastText mt-4 flex justify-center"
            >
              {isLoading || isLoadingOrder || refreshLoading ? (
                <LoadingSpin />
              ) : merchant?.whatsapp_instance &&
                merchant.whatsapp_instance.is_connected &&
                merchant.whatsapp_instance.config_details
                  .send_orders_to_customers ? (
                'Quero acompanhar meu pedido'
              ) : (
                'Continuar'
              )}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default StatusPage;
