import { CustomerForm, ICustomerForm } from '@onbeef/components/customerForm';
import {
  LoadingIcon,
  Toast,
  formatBirthday,
  maskCPF,
  maskPhone,
  parseBirthday,
} from '@onbeefapp/constants';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import HeaderBar from '../../../components/Headers/HeaderBar';
import { useAuthStore } from '../../../stores/login';
import { useUserStore } from '../../../stores/user';
import { useUpdateCustomer } from '../../../queries/customer';
import { AxiosError } from 'axios';
import { UpdateCustomerPayload } from '../../../queries/customer/types';
import { Skeleton } from '@onbeef/components/skeleton';
import { useSlug } from '../../../hooks/useSlug';
import { useMerchantCache } from '../../../queries/home';

type ProfileInfoForm = ICustomerForm;

export const ProfileInfo: React.FC = () => {
  const params = useSlug();
  const { isLoading: isMerchantLoading } = useMerchantCache(params.slug);

  const updateUser = useAuthStore((state) => state.updateUser);
  const user = useUserStore();

  const methods = useForm<ProfileInfoForm>();

  const { mutateAsync: updateCustomer, isLoading } = useUpdateCustomer();

  const onSubmit = async (data: ProfileInfoForm) => {
    try {
      const payload: UpdateCustomerPayload = {};

      type CommonKey = keyof Omit<UpdateCustomerPayload, 'address'>;

      Object.keys(data).forEach((key) => {
        const k = key as CommonKey;
        if ((key === 'taxpayer_id_number' && user[k]) || key === 'phone') {
          return;
        }
        if (user.hasOwnProperty(k) && data[k] && data[k] !== user[k]) {
          if (k === 'birthday') {
            payload[k] = formatBirthday(data[k] as string);
            return;
          }
          payload[k] = data[k] as string;
        }
      });

      const res = await updateCustomer(payload);
      updateUser(res.data);
      res.message && Toast.success(res.message);
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    }
  };

  React.useEffect(() => {
    if (user) {
      methods.setValue('name', user.name);
      methods.setValue('gender', user.gender);
      methods.setValue('birthday', parseBirthday(user.birthday));
      methods.setValue('email', user.email);
      methods.setValue('taxpayer_id_number', maskCPF(user.taxpayer_id_number));
      methods.setValue('phone', maskPhone(user.phone));
    }
  }, [user]);

  const emailDisabled = user.email != null && user.email !== '';
  const cpfDisabled =
    user.taxpayer_id_number != null && user.taxpayer_id_number !== '';

  return (
    <div className="w-full h-full p-4 space-y-2">
      <HeaderBar>
        <span className="text-sm font-normal text-center w-full">
          Meus dados
        </span>
      </HeaderBar>

      <FormProvider {...methods}>
        <form
          className="flex flex-col p-2 gap-4"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Skeleton
            isLoading={isMerchantLoading}
            repeatQuantity={8}
            className="w-full h-10"
          >
            <span className="text-sm font-medium">
              Esses dados estão vinculados a sua conta. Altere ou adicione mais
              informações.
            </span>
            <CustomerForm
              withPassword={false}
              haveAccount
              emailDisabled={emailDisabled}
              cpfDisabled={cpfDisabled}
            />
            <button
              type="submit"
              className="rounded-lg bg-primary text-contrastText px-5 py-2 w-full h-10 text-center self-center flex justify-center"
            >
              {isLoading ? <LoadingIcon /> : 'Salvar'}
            </button>
          </Skeleton>
        </form>
      </FormProvider>
    </div>
  );
};
