import { Skeleton } from '@onbeef/components/skeleton';
import { useUserStore } from '../stores/user';
import { useMerchantCache } from '../queries/home';
import { useSlug } from '../hooks/useSlug';
import { useAuthStore } from '../stores/login';
import { Link } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/24/solid';

export const ProfileHeader: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const name = useUserStore((state) => state.name);

  return (
    <Skeleton
      width="w-full"
      height="30px"
      borderRadius="10px"
      isLoading={isLoading}
    >
      <div className="w-full flex items-center p-2 gap-2 justify-end">
        {isAuthenticated ? (
          <Link
            to="/profile"
            className="rounded-full bg-primary w-8 h-8 text-sm text-center flex justify-center items-center text-contrastText"
          >
            {name.charAt(0).toUpperCase()}
          </Link>
        ) : (
          <Link to="/login" className="font-medium text-sm">
            <UserIcon className="h-5 w-5 text-gray-500" />
          </Link>
        )}
      </div>
    </Skeleton>
  );
};
