import {
  HelpIcon,
  Toast,
  currencyFormat,
  currencyToFloat,
  formatCurrency,
} from '@onbeefapp/constants';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Slide } from 'react-toastify';
import LoadingSpin from '../../components/LoadingSpin';
import Skeleton from '../../components/Skeleton/Skeleton';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useCartStore } from '../../stores/cart';
import { useMerchantStore } from '../../stores/merchant';
import { useOrderDetails } from '../../stores/order-details';
import { useUserStore } from '../../stores/user';
import { getMarginTax, getRestFreeFreigth } from '../../utils/CartUtils';
import { DeliveryType, StepType } from '../../utils/constants';
import FormCheckout from '../../components/Forms/FormCheckout';
import { useGetSchedulings } from '../../queries/order';
import Tooltip from '@onbeef/components/tooltip';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

interface Props {
  showSummary?: boolean;
  onClickContinue?: () => void;
  orderId?: string;
  extensionCustomCouponCode?: string;
  extensionCustomDeliveryFee?: number | string;
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
}

const CartSummary: React.FC<Props> = ({
  showSummary = true,
  onClickContinue,
  orderId,
  extensionCustomCouponCode,
  extensionCustomDeliveryFee,
  setExtensionCustomCouponCode,
  setExtensionCustomDeliveryFee,
}) => {
  const params = useSlug();
  const isExtension = useCartStore((state) => state.isExtension);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [percentageProgress, setPercentageProgress] = React.useState('0%');
  const { restFreeFreigth, percentage } = getRestFreeFreigth();
  const { productsCountIndividual, coupon, setCoupon, deliveryTime } =
    useCartStore((state) => ({
      deliveryTime: state.deliveryTime,
      productsCountIndividual: state.productsCountIndividual,
      coupon: state.coupon,
      setCoupon: state.setCoupon,
    }));
  const { deliveryType, setCurrentStep, currentStep } = useOrderDetails(
    (state) => ({
      deliveryType: state.deliveryType,
      setCurrentStep: state.setCurrentStep,
      currentStep: state.currentStep,
    }),
  );

  const { isMerchantOpen, delivery, merchant, schedules } = useMerchantStore(
    (state) => ({
      isMerchantOpen: state.isMerchantOpen,
      delivery: state.delivery,
      merchant: state.merchant,
      schedules: state.schedules,
    }),
  );

  const { isLoading } = useMerchantCache(params.slug);

  const { data } = useGetSchedulings(merchant?.id);

  const { track } = React.useContext(TrackingContext);

  React.useEffect(() => {
    if (percentage) {
      setPercentageProgress(`${percentage}`);
    }
  }, [percentage]);

  React.useEffect(() => {
    if (productsCountIndividual <= 0 && !isExtension) {
      navigate(`/`);
      track(`OnCustomerAtCartClear`, {});
      track(`OnCustomerLeaveCart`, {});
      Toast.error('Sua sacola está vazia');
    }
  }, [productsCountIndividual]);

  const {
    frete,
    withFreteGratisDiscount,
    isFreteGratis,
    subtotal,
    subtotalWithDiscount,
    hasProductPerKilo,
    discountFormatted,
    loyaltyGiftFormatted,
    loyaltyRewardFormatted,
    subtotalFormatted,
    totalFormatted,
    isCouponLoading,
    errorRadius,
    clubDiscountAvailable,
    hasClubDiscount,
    totalClubDiscountFormatted,
    userClubDiscountFormatted,
  } = React.useContext(OrderDetailsContext);

  React.useEffect(() => {
    if (errorRadius == true) {
      Toast.error('Ainda não atendemos na sua região');
      Toast.success('Clique aqui para chamar a loja no whatsapp', {
        position: 'top-center',
        className: 'toastify-sucess',
        autoClose: 50000,
        closeButton: false,
        icon: true,
        onClick: () => {
          window.open(
            `https://api.whatsapp.com/send?phone=${merchant?.phone}&text=Olá, vocês conseguem entregar no bairro ${address?.neighborhood}, ${address?.city}?`,
            '_blank',
          );
        },
        hideProgressBar: true,
        closeOnClick: true,
        transition: Slide,
        theme: 'colored',
      });
    }
  }, [errorRadius]);

  let blockMinValue = false;

  if (deliveryType === DeliveryType.DELIVERY) {
    if (delivery) {
      if (delivery.amount_order_min) {
        blockMinValue =
          subtotal >= parseInt(delivery.amount_order_min) ? false : true;
      }
    }
  }

  const buttonLabel = 'Continuar';

  const handleClick = () => {
    setOpen(!open);
  };

  const { address } = useUserStore((state) => ({
    address: state.address,
  }));

  const addCoupon = () => {
    if (coupon) return;
    track(`OnCustomerLeaveCart`, {});
    track(`OnCustomerAtCartViewCoupon`, {});
    navigate(`/coupon`);
  };

  const removeCoupon = () => {
    setCoupon(undefined);
  };

  const verifyContinue = () => {
    if (
      data?.status === 'ACTIVE' &&
      !deliveryTime &&
      deliveryType === DeliveryType.DELIVERY &&
      address &&
      isMerchantOpen &&
      schedules?.open === false &&
      schedules?.receive_orders_when_closed === true
    ) {
      setCurrentStep(StepType.SCHEDULING);
      Toast.error(
        'Esta loja está fechada, para agendar o seu pedido você deve selecionar o "Tempo de entrega"',
      );

      return;
    }

    if (deliveryType === DeliveryType.DELIVERY) {
      if (!address) {
        Toast.error('Selecione um endereço');
        setCurrentStep(StepType.ADDRESS);
        return;
      }

      if (errorRadius == true) {
        Toast.error('Ainda não atendemos na sua região');
        return;
      }

      if (blockMinValue && delivery && delivery) {
        return Toast.error(
          `O valor mínimo para entrega é de ${currencyFormat(
            parseInt(delivery.amount_order_min),
          )}`,
        );
      }
    }
    track(`OnCustomerViewCheckout`, {});
    return navigate(`/checkout`);
  };

  if (isExtension && !showSummary) {
    if (currentStep === StepType.ADDRESS) return;
    return (
      <FormCheckout
        onClickContinue={onClickContinue}
        orderId={orderId}
        extensionCustomCouponCode={extensionCustomCouponCode}
        extensionCustomDeliveryFee={extensionCustomDeliveryFee}
        setExtensionCustomCouponCode={setExtensionCustomCouponCode}
        setExtensionCustomDeliveryFee={setExtensionCustomDeliveryFee}
      />
    );
  }

  let finalTotal: number | string | undefined = currencyToFloat(totalFormatted);
  const floatCustomDeliveryFee = currencyToFloat(
    String(extensionCustomDeliveryFee) ?? 0,
  );
  if (floatCustomDeliveryFee > 0) {
    finalTotal = !isNaN(Number(frete))
      ? currencyToFloat(totalFormatted) - currencyToFloat(frete)
      : currencyToFloat(totalFormatted);
    finalTotal += currencyToFloat(String(extensionCustomDeliveryFee));
    finalTotal = formatCurrency(finalTotal);
  } else {
    finalTotal = totalFormatted;
  }

  return (
    <React.Fragment>
      <div
        className={`${
          !isExtension && 'fixed bottom-0'
        } flex flex-col items-start transition-all justify-start w-full  ${
          !isExtension
            ? 'p-3 mt-2 h-auto'
            : currentStep === StepType.ADDRESS
              ? 'hidden'
              : ''
        } bg-white rounded-t-xl`}
      >
        {!isExtension && clubDiscountAvailable && (
          <Skeleton isLoading={isLoading} className="w-full rounded-md h-4">
            <div className="flex items-center justify-center w-full mb-2">
              <span className="text-sm text-green-600 font-medium">
                {hasClubDiscount ? (
                  `Você economizou ${userClubDiscountFormatted} nessa compra.`
                ) : (
                  <Link to="/club" state={{ goBack: '/cart' }}>
                    Junte-se ao clube e economize {totalClubDiscountFormatted}{' '}
                    nessa compra!
                  </Link>
                )}
              </span>
            </div>
          </Skeleton>
        )}
        {!isExtension && loyaltyRewardFormatted && (
          <Skeleton isLoading={isLoading} className="w-full rounded-md h-4">
            <div className="flex flex-col items-center justify-center w-full mb-2">
              <span className="text-sm text-green-600 font-medium">
                Prêmio por fidelidade: {loyaltyRewardFormatted}
              </span>
              {loyaltyGiftFormatted && (
                <span className="text-sm text-green-600 font-medium">
                  Prêmio para novos clientes: {loyaltyGiftFormatted}
                </span>
              )}
            </div>
          </Skeleton>
        )}
        {/* Add coupon */}
        {!isExtension && (
          <div className="flex items-center justify-center w-full mb-3 text-sm">
            <Skeleton isLoading={isLoading} className="w-full rounded-md h-11">
              <div className="flex items-center justify-between w-full p-2 bg-white rounded-lg shadow-sm">
                <div className="flex items-center ml">
                  <svg
                    version="1.1"
                    className="inline w-[20px] text-primary mr-2"
                    x="0px"
                    y="0px"
                    viewBox="0 0 32 32"
                  >
                    <path
                      style={{
                        fill: 'none',
                        stroke: 'currentColor',
                        strokeWidth: '2',
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: '10',
                      }}
                      d="M29,12.6V10c0-0.6-0.4-1-1-1h-6.3c-0.3,0.6-1,1-1.7,1s-1.4-0.4-1.7-1H4c-0.6,0-1,0.4-1,1v2.6c1.2,0.7,2,2,2,3.4
	                s-0.8,2.8-2,3.4V22c0,0.6,0.4,1,1,1h14.3c0.3-0.6,1-1,1.7-1s1.4,0.4,1.7,1H28c0.6,0,1-0.4,1-1v-2.6c-1.2-0.7-2-2-2-3.4
	                S27.8,13.2,29,12.6z"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      x1="20"
                      y1="12"
                      x2="20"
                      y2="15"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      x1="20"
                      y1="20"
                      x2="20"
                      y2="17"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      x1="10"
                      y1="19"
                      x2="14"
                      y2="13"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      x1="9"
                      y1="12"
                      x2="9"
                      y2="15"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      x1="15"
                      y1="17"
                      x2="15"
                      y2="20"
                    />
                    <rect
                      x="-360"
                      y="-360"
                      fill="none"
                      width="536"
                      height="680"
                    />
                  </svg>
                  {coupon ? <span>{coupon.code}</span> : 'Cupom de desconto'}
                </div>
                <button className="mr text-primary" onClick={addCoupon}>
                  {coupon ? (
                    <button onClick={removeCoupon}>Remover</button>
                  ) : (
                    'Adicionar'
                  )}
                </button>
              </div>
            </Skeleton>
          </div>
        )}

        {!blockMinValue && (
          <div
            className={`flex flex-col w-full mt-1 transition-all mb-4 ${
              open
                ? `${
                    coupon || subtotal !== subtotalWithDiscount
                      ? 'h-[87px]'
                      : 'h-[67px]'
                  }`
                : 'h-5'
            }`}
          >
            <div className={`flex flex-row justify-between w-full mt-1s`}>
              <Skeleton isLoading={isLoading} className="w-1/2 h-5 rounded-lg">
                <div
                  className="font-semibold text-sm flex items-center"
                  onClick={handleClick}
                >
                  <span className="mr-1">
                    {coupon
                      ? `Total ${
                          hasProductPerKilo ? 'estimado' : ''
                        } com desconto`
                      : deliveryType === DeliveryType.DELIVERY
                        ? `Total ${
                            hasProductPerKilo ? 'estimado' : ''
                          } com a entrega`
                        : `Total${hasProductPerKilo ? ' estimado' : ''}`}
                  </span>
                  {hasProductPerKilo && (
                    <Tooltip text="O valor dos produtos por peso pode variar de acordo com o peso medido no envio. Estabelecemos essa margem para garantir que você não pague nada além do combinado. Se o peso final for menor que o inicialmente cobrado, faremos o estorno da diferença.">
                      <HelpIcon className="text-gray-400" />
                    </Tooltip>
                  )}
                </div>
              </Skeleton>
              <Skeleton isLoading={isLoading} className="w-24 h-5 rounded-lg">
                <div className="flex flex-row items-center gap-1">
                  <div className="font-semibold text-sm" onClick={handleClick}>
                    {isCouponLoading ? (
                      <LoadingSpin color="primary" />
                    ) : (
                      finalTotal
                    )}
                  </div>
                  <div className="place-content-center text-black">
                    {open ? (
                      <ChevronUpIcon
                        className="w-4 h-4"
                        stroke="black"
                        strokeWidth={2}
                      />
                    ) : (
                      <ChevronDownIcon
                        className="w-4 h-4"
                        stroke="black"
                        strokeWidth={2}
                      />
                    )}
                  </div>
                </div>
              </Skeleton>
            </div>
            {open && (
              <div
                className={`flex flex-row justify-between w-full mt-1 pr-[22px]`}
              >
                <Skeleton
                  isLoading={isLoading}
                  className="w-1/2 h-5 rounded-lg"
                >
                  <div className="font-semibold text-sm text-[#787878]">
                    Subtotal
                  </div>
                </Skeleton>
                <Skeleton isLoading={isLoading} className="w-24 h-5 rounded-lg">
                  <div className="flex flex-row">
                    <div className="font-semibold text-sm text-[#787878]">
                      {subtotalFormatted}
                    </div>
                  </div>
                </Skeleton>
              </div>
            )}
            {deliveryType === DeliveryType.DELIVERY && (
              <>
                {open && (
                  <div
                    className={`flex flex-row justify-between w-full mt-1 pr-[22px]`}
                  >
                    <Skeleton
                      isLoading={isLoading}
                      className="w-1/2 h-5 rounded-lg"
                    >
                      <div className="font-semibold text-sm text-[#787878]">
                        Taxa de entrega
                      </div>
                    </Skeleton>
                    <Skeleton
                      isLoading={isLoading}
                      className="w-24 h-5 rounded-lg"
                    >
                      <div className="flex flex-row">
                        <div className="font-semibold text-sm text-[#787878]">
                          {withFreteGratisDiscount ? (
                            <>
                              <s>
                                {extensionCustomDeliveryFee &&
                                floatCustomDeliveryFee > 0
                                  ? extensionCustomDeliveryFee
                                  : frete}
                              </s>{' '}
                              <span className="text-green-600">Grátis</span>
                            </>
                          ) : extensionCustomDeliveryFee &&
                            floatCustomDeliveryFee > 0 ? (
                            extensionCustomDeliveryFee
                          ) : (
                            frete
                          )}
                        </div>
                      </div>
                    </Skeleton>
                  </div>
                )}
              </>
            )}
            {getMarginTax() > 0 && (
              <>
                {open && (
                  <div
                    className={`flex flex-row justify-between w-full mt-1 pr-[22px]`}
                  >
                    <Skeleton
                      isLoading={isLoading}
                      className="w-1/2 h-5 rounded-lg"
                    >
                      <div className="font-semibold text-sm text-[#787878]">
                        Margem de variação de peso
                      </div>
                    </Skeleton>
                    <Skeleton
                      isLoading={isLoading}
                      className="w-24 h-5 rounded-lg"
                    >
                      <div className="flex flex-row">
                        <div className="font-semibold text-sm text-[#787878]">
                          {currencyFormat(getMarginTax())}
                        </div>
                      </div>
                    </Skeleton>
                  </div>
                )}
              </>
            )}
            {subtotal !== subtotalWithDiscount && (
              <>
                {open && (
                  <div
                    className={`flex flex-row justify-between w-full mt-1 pr-[22px] text-green-600`}
                  >
                    <Skeleton
                      isLoading={isLoading}
                      className="w-1/2 h-5 rounded-lg"
                    >
                      <div className="font-semibold text-sm">Desconto</div>
                    </Skeleton>
                    <Skeleton
                      isLoading={isLoading}
                      className="w-24 h-5 rounded-lg"
                    >
                      <div className="flex flex-row">
                        <div className="font-semibold text-sm">
                          {isCouponLoading ? (
                            <LoadingSpin color="primary" />
                          ) : (
                            discountFormatted
                          )}
                        </div>
                      </div>
                    </Skeleton>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {/* Messagem caso pedido mínimo não seja igual ou maior */}
        {blockMinValue &&
          deliveryType === DeliveryType.DELIVERY &&
          delivery && (
            <div className={`flex flex-col w-full mt-1 transition-all`}>
              <div className={`flex flex-row justify-between w-full mt-1`}>
                <div className="font-semibold text-sm text-[#d90016]">
                  O pedido mínimo dessa loja é{' '}
                  {delivery &&
                    currencyFormat(parseInt(delivery?.amount_order_min))}{' '}
                  sem contar com a taxa de entrega
                </div>
              </div>
            </div>
          )}

        {/* Progresso para frete grátis */}
        {restFreeFreigth > 0 &&
          deliveryType === DeliveryType.DELIVERY &&
          !blockMinValue &&
          !isFreteGratis && (
            <div className="flex flex-col items-center justify-center w-full mt-3 text-sm">
              <Skeleton isLoading={isLoading} className="w-9/12 h-5 rounded-lg">
                <div>
                  Falta {currencyFormat(restFreeFreigth)} para
                  <span className="ml-1 font-semibold text-green-600">
                    entrega grátis
                  </span>
                </div>
              </Skeleton>

              <Skeleton
                isLoading={isLoading}
                className="w-full h-3 mt-4 rounded-full"
              >
                <div className="flex flex-row items-start justify-start w-full h-2 mt-4 ml-2 bg-gray-200 rounded-full">
                  <div
                    className={`w-[${percentageProgress}] h-full bg-primary rounded-full transition-all duration-500`}
                    style={{ width: percentageProgress }}
                  ></div>
                </div>
              </Skeleton>
            </div>
          )}

        {/* Button confirm */}
        {!isExtension && (
          <div className="flex flex-row items-center justify-center w-full mt-3">
            <Skeleton isLoading={isLoading} className="w-full rounded-md h-11">
              <button
                className=" p-2 text-contrastText bg-primary rounded-md w-full text-sm"
                onClick={() => {
                  if (onClickContinue) {
                    return onClickContinue();
                  }
                  verifyContinue();
                }}
              >
                {buttonLabel}
              </button>
            </Skeleton>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CartSummary;
