import {
  BlockIcon,
  FiltersType,
  LoadingIcon,
  Product,
} from '@onbeefapp/constants';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import CartButton from '../../components/CartButton';
import DrawerGrams from '../../components/Drawers/DrawerGrams';
import HeaderBar from '../../components/Headers/HeaderBar';
import ListProduct from '../../components/Lists/ListProduct';
import RightSide from '../../components/RightSide/RightSide';
import SearchBar from '../../components/Search/SearchBar';
import { useGetCategoryProducts } from '../../queries/categories-products';
import { useCartStore } from '../../stores/cart';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';
import Sidebar from '../Sidebar/Sidebar';

const Category: React.FC = () => {
  const merchant = useMerchantStore((state) => state.merchant);
  const [products, setProducts] = React.useState<Product[]>([]);
  const params = useParams();
  const { categories, highlights, setCategoryProducts, filters } =
    useProductsStore((state) => ({
      categories: state.categories,
      highlights: state.highlights,
      setCategoryProducts: state.setCategoryProducts,
      filters: state.filters,
    }));
  const categoryId = categories.find(
    (category) => category.slug === params.name,
  )?.id;
  const categoryName =
    params.name === 'destaques'
      ? 'Destaques'
      : categories.find((category) => category.slug === params.name)?.name;

  const { productsCountIndividual } = useCartStore((state) => ({
    productsCountIndividual: state.productsCountIndividual,
  }));
  const showCart = productsCountIndividual > 0;

  const { mutateAsync: getProductsData, isLoading } = useGetCategoryProducts();

  React.useEffect(() => {
    const run = async () => {
      if (categoryId) {
        const productsData = await getProductsData({
          merchant_id: merchant?.id,
          category_id: categoryId,
        });
        const prepProducts = setCategoryProducts(
          categoryId,
          productsData || [],
        );
        setProducts(prepProducts);
      }
    };
    run();
  }, [categoryId]);

  return (
    <div className="w-full">
      <Helmet>
        <meta
          name="title"
          content={`${merchant?.name} categoria ${categoryName}`}
        />
        <meta
          name="description"
          content={`Produtos da categoria ${categoryName}`}
        />
        <meta
          name="keywords"
          content={[categoryName, 'produtos']
            .filter((v) => v != undefined)
            .join(', ')}
        />
      </Helmet>
      {/* Sidebar for desktop view */}
      <Sidebar nameSelected={params.name} />
      <RightSide>
        <div className="p-2 pb-28">
          <div className="fixed top-0 z-40 pt-3 pr-[17px] bg-[#f5f5f5] pb-px transition-all lg:w-[71%] duration-300 ease-in-out w-full">
            {/* HeaderBar */}
            <HeaderBar backToMainPage={true} isCategory={true}>
              <div className="w-full text-sm font-normal text-center">
                {categoryName}
              </div>
            </HeaderBar>

            {/* SearchInput */}
            {/* <ProductSearchInput cart={true} /> */}
            {params.name !== 'destaques' && (
              <SearchBar cart={true} nameSelected={params.name} />
            )}
          </div>

          {/* Produtos da categoria */}
          {/* Se houver categoryId e a quantidade de produtos na categoria for maior que zero, mostrar, senão mostar tela de produtos não encontrados */}
          <div
            className={`${
              params.name !== 'destaques'
                ? filters?.some((f) => f.type === FiltersType.CUTS)
                  ? 'mt-[190px]'
                  : 'mt-[160px]'
                : 'mt-[50px]'
            }`}
          >
            {categoryId && products ? (
              <ListProduct products={products} />
            ) : params.name === 'destaques' ? (
              <ListProduct products={highlights} />
            ) : (
              <div className="flex flex-col pt-[50px]">
                <center>
                  <BlockIcon className="min-w-16 min-h-16 text-[#bfbfbf]" />

                  <div className="prose prose-sm text-[#bfbfbf] mt-6">
                    Produtos não encontrados
                  </div>
                </center>
              </div>
            )}

            {isLoading && (
              <div className="flex flex-col pt-[50px]">
                <center>
                  <LoadingIcon className="min-w-16 min-h-16 text-[#bfbfbf]" />

                  <div className="prose prose-sm text-[#bfbfbf] mt-6">
                    Carregando...
                  </div>
                </center>
              </div>
            )}
          </div>
        </div>
        {/* Drawer com as especifições da gramatura do produto */}
        <DrawerGrams />

        {/* Cart */}
        {showCart && <CartButton />}
      </RightSide>
    </div>
  );
};

export default Category;
